<template>
  <div>
    <h4 class="text-black font-medium mb-3">
      {{ $t('mcs.send.form[0]') }}
    </h4>
    <!-- <p class="leading-tight text-grey-darker mb-3 text-sm">
      Name your campaign, select when you do want to process the campaign and you're done.
    </p> -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.prevent.native
    >
      <p class="text-xs label__asterisk mb-2">
         {{ $t('mcs.send.form[1]') }}
      </p>
      <el-form-item prop="campaignName">
        <el-input
          v-model="form.campaignName"
          class="text-xs w-3/5"
          :rows="4"
          size="small"
          :placeholder="$t('mcs.send.form[5]')"
        />
      </el-form-item>
      <div class="flex mb-2 w-3/5">
        <p class="flex-1 text-xs">
            {{ $t('fields.client_msg_id') }}
        </p>
        <p class="flex-1 text-right text-xs text-grey">
           {{ $t('validations.optional') }}
        </p>
      </div>
      <el-form-item prop="clientMsgId">
        <el-input
          v-model="form.clientMsgId"
          class="text-xs w-3/5"
          :rows="4"
          size="small"
          :placeholder="$t('mcs.send.form[6]')"
          :disabled="!form.campaignName" />
      </el-form-item>
      <div v-if="hasSchedule">
        <hr class="mx-0 mt-5 mb-10 h-px bg-grey w-48">
        <p class="leading-tight text-xs">
          {{ $t('mcs.send.form[2]') }}
        </p>
        <el-form-item>
          <el-radio v-model="form.sendNow"
            :label="Boolean(true)" size="small">
            {{ $t('mcs.send_now') }}
          </el-radio>
          <el-radio v-model="form.sendNow" size="small"
          :label="Boolean(false)">
            {{ $t('mcs.sched_later') }}
          </el-radio>
        </el-form-item>
      </div>
      <div v-if="!form.sendNow && hasSchedule">
        <div class="flex">
          <div class="flex-shrink mr-10">
            <p class="text-xs mb-2 label__asterisk">
              {{ $t('mcs.send.form[3]') }}
            </p>
            <el-form-item>
              <el-date-picker
                v-model="form.scheduleDate"
                :disabled="disableField"
                type="datetime"
                clearable
                size="small"
                :placeholder="$t('mcs.send.form[3]')"
                format="dd MMM yyyy HH:mm"
                :picker-options="pickerOptions" />
            </el-form-item>
          </div>
          <div class="flex-auto">
            <p class="text-xs mb-2 label__asterisk">
              {{ $t('fields.timezone') }}
            </p>
            <el-form-item>
              <el-select
                v-model="form.scheduleTimezone"
                :disabled="disableField"
                filterable
                style="width: 60%"
                size="small"
                :placeholder="$t('fields.timezone')">
                <el-option
                  v-for="tz in timezones"
                  :key="tz.TimeZoneId"
                  :label="tz.Combined"
                  :value="tz.TimeZoneId" />
              </el-select>
            </el-form-item>
          </div>
        </div>
        <p class="text-grey-dark text-xs">
          {{ $t('mcs.send.form[4]') }}
        </p>
      </div>
    </el-form>
  </div>
</template>
<script>
import moment from 'moment';


export default {
  name: 'send_campaign_form',

  props: {
    user: {
      type: Object,
      default: () => ({}),
    },

    data: {
      type: Object,
      default: () => ({}),
    },

    timezones: {
      type: Array,
      default: () => [],
    },

    hasSchedule: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    form: {
      deep: true,
      handler(n) {
        if (n.sendNow) {
          this.form.scheduleDate = '';
          this.scheduleTimezone = '';
        } else {
          if (!n.scheduleDate) {
            this.form.scheduleDate = moment().format('llll');
          }

          if (!n.scheduleTimezone) {
            this.setTimeZone();
          }
        }

        const obj = n;

        if (this.$refs.form) {
          const self = this;

          this.$refs.form.validateField('campaignName', (err) => {
            if (!err) {
              this.$emit('update-campaign-details', obj);
            } else {
              obj.campaignName = '';
              self.$emit('update-campaign-details', obj);
            }
          });

          if (n.clientMsgId) {
            this.$refs.form.validateField('clientMsgId', (err) => {
              if (!err) {
                this.$emit('update-campaign-details', obj);
              } else {
                obj.clientMsgId = '';
                self.$emit('update-campaign-details', obj);
              }
            });
          }

          if (!n.sendNow) {
            this.$refs.form.validateField('scheduleDate', (err) => {
              if (!err) {
                this.$emit('update-campaign-details', obj);
              } else {
                obj.scheduleDate = '';
                self.$emit('update-campaign-details', obj);
              }
            });
          }
        }
      },
    },
  },

  data() {
    const self = this;

    return {
      form: {
        campaignName: '',
        clientMsgId: '',
        sendNow: true,
        scheduleDate: '',
        scheduleTimezone: undefined,
      },

      rules: {
        campaignName: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.campaign_name') }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],

        scheduledDate: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.schedule_date') }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],

        clientMsgId: [
          {
            max: 50,
            message: self.$t('validations.char_limit', { value: '50' }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },

      pickerOptions: {
        disabledDate(picker) {
          const today = new Date();
          const future = moment().add(30, 'd');

          return picker.getTime() < today.getTime() - 86400000 || picker.getTime() > future;
        },
        minTime: '03:00',
      },
    };
  },

  created() {
    this.form.campaignName = `${moment().format('YYYYMMDD-hh:mmA')}-CAMPAIGN`;

    if (Object.keys(this.data).length) {
      this.form.scheduleTimezone = this.data.scheduleTimezone || '';
      this.form.clientMsgId = this.data.clientMsgId || '';
      this.form.scheduleDate = this.data.scheduleDate || '';
    }
  },

  methods: {
    setTimeZone() {
      const tzs = this.timezones;

      const userTz = this.user.TimeZoneName || this.$jstz_mc.determine().name();

      const [tzByName] = tzs.filter(tz => tz.TimeZoneName === userTz);

      let tzn = tzByName;

      // if timezone is not in the list, try SG timezone as default
      if (!tzn) {
        const [sgTz] = tzs.filter(tz => tz.TimeZoneName === 'Asia/Singapore');
        tzn = sgTz;
      }

      this.form.scheduleTimezone = tzn.TimeZoneId;
    },
  },
};
</script>
