<template>
  <el-row
    v-if="step === 'SEND'"
    class="w-full"
  >
    <el-col :span="16">
      <SendForm
        :user="user"
        :data="sendData"
        :timezones="timezones"
        :has-schedule="form.Type.match(/SMS/)"
        @update-campaign-details="updateCampaignDetails"
      />
      <LowBalanceAlert
        v-if="isPostPaidLowBalance() || isPrepaidLowBalance()"
        :is-prepaid="isPrepaidLowBalance()"
        :is-postpaid="isPostPaidLowBalance()"
        :balance="userBalance.CreditsAmount"
        :currency="userBalance.Currency"
      />
      <div class="mt-10">
        <div v-if="!canSendCampaigns" class="text-red-light mb-2 text-sm">
          {{ $t('mcs.subscription_warning') }}
        </div>
        <el-button
          @click="$emit('discard')"
        >
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          type="primary"
          class="w-1/6"
          :disabled="disabledSubmit() || !canSendCampaigns"
          @click="submitCampaign()"
          id="mcs-submit-campaign"
          :loading="loading">
          {{ $t('actions.submit') }}
        </el-button>
      </div>
    </el-col>
    <el-col
      :span="8"
      class="send-details">
      <div>
        <Details
          :messaging-type="form.Type"
          :sub-account="form.SubAccountId"
          :channels="channels"
        />
        <Recipients
          :total="getTotals().totalValidRecipients"
          :valid-percentage="getTotals().validPercentage"
        />
        <MessagePreview
          :has-whats-app="hasWhatsApp()"
          :message-type="form.Type"
          :header="form.Message.header"
          :footer="form.Message.footer"
          :message="form.Message.message"
          :wa-buttons="form.Message.waButtons"
          :image-inputs="form.Message.imageInputs"
          :button-inputs="form.Message.buttonInputs"
          :channel="getMainChannel()"
          :custom-fields="form.Message.customFields"
          :variables="variables"
          :sms-parts="form.Message.smsParts ? form.Message.smsParts : 0"
          :total-sms="getTotals().totalSmsMsgs"
          :total-ca-msgs="getTotals().totalCaMsgs"
          :total-voice-msgs="getTotals().totalVoiceMsgs"
        />
        <EstimatedCost v-if="showCost" :estimated-cost="getEstimatedCostDisplay()" />
        <ProcessWhen
          :send-now="sendData.sendNow"
          :schedule-date="sendData.scheduleDate"
          :estimated-process-time="getEstimatedTimeDisplay()"
          :schedule-timezone="sendData.scheduleTimezone"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CampaignDetailsMixin from '@/mixins/campaign-details';
import SendForm from './partials/Form.vue';
import Details from './partials/Details.vue';
import Recipients from './partials/Recipients.vue';
import MessagePreview from './partials/MessagePreview.vue';
import EstimatedCost from './partials/EstimatedCost.vue';
import ProcessWhen from './partials/ProcessWhen.vue';
import LowBalanceAlert from './partials/LowBalanceAlert.vue';


export default {
  name: 'send',

  components: {
    SendForm,
    Details,
    Recipients,
    MessagePreview,
    EstimatedCost,
    ProcessWhen,
    LowBalanceAlert,
  },

  mixins: [CampaignDetailsMixin],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sendData: {
        sendNow: true,
        campaignName: '',
        clientMsgId: '',
        scheduleDate: '',
        scheduleTimezone: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
      canSendCampaigns: 'user/getCanSendCampaigns',
      userBalance: 'user/getUserBalance',
      timezones: 'common/getTimeZones',
      step: 'getStep',
      form: 'getForm',
      hasPricingEntitlement: 'user/getPricingEntitlementAccess',
    }),

    showCost() {
      return this.hasPricingEntitlement;
    },
  },

  created() {
    // checking balance is for chat apps
    if (this.form.Type.match(/SMS/)) {
      this.fetchBalance();
    }

    if (this.form.CampaignDetails) {
      this.sendData = this.form.CampaignDetails;
    }

    console.log('form', this.form);
  },

  methods: {
    ...mapActions({
      getBalance: 'user/getBalance',
    }),

    ...mapMutations({
      updateForm: 'UPDATE_FORM',
      setStep: 'SET_STEP',
    }),

    updateCampaignDetails(sform = {}) {
      this.sendData = { ...this.sendData, ...sform };
    },

    async fetchBalance() {
      await this.getBalance().catch((err) => {
        this.$showError(this, err);
      });
    },

    isPostPaidLowBalance() {
      if (this.form.Type.match(/SMS/)) {
        const { CreditsAmount, OverDraftAuthorized } = this.userBalance;
        const { PriceRange: pr } = this.form;

        const { IsPostPaid } = this.user;
        const RemainingBal = CreditsAmount - pr.totalMin;

        return IsPostPaid
          && (CreditsAmount < OverDraftAuthorized || RemainingBal < OverDraftAuthorized);
      }

      return false;
    },

    isPrepaidLowBalance() {
      if (this.form.Type.match(/SMS/)) {
        const { CreditsAmount } = this.userBalance;
        const { PriceRange: pr } = this.form;

        const { IsPostPaid } = this.user;
        const RemainingBal = CreditsAmount - pr.totalMin;

        return (!IsPostPaid && RemainingBal <= 0);
      }

      return false;
    },

    submitCampaign() {
      const { clientMsgId } = this.sendData;
      if (clientMsgId && clientMsgId.length) {
        this.sendData.clientMsgId = clientMsgId.replace(/=HYPERLINK\(/ig, '');
      }

      this.updateForm({ CampaignDetails: this.sendData });

      // console.log('form', this.form);
      this.$emit('submit');
    },

    disabledSubmit() {
      const {
        campaignName,
        clientMsgId,
        sendNow,
        scheduleDate,
        scheduleTimezone,
      } = this.sendData;

      const {
        Type: type,
        Recipients: rp,
        PriceRange: pr,
        Message: message,
      } = this.form;

      if (
        !campaignName
        || !this.form
        || (clientMsgId && clientMsgId.length > 50)
        || (type.match(/SMS/) && !sendNow && (!scheduleDate || !scheduleTimezone))
        || (type.match(/SMS/) && (this.isPostPaidLowBalance() || this.isPrepaidLowBalance()))
        || !rp
        || !message
        || (type.match(/SMS/) && !pr)
        || (type.match(/SMS/) && pr && (!pr.totalMin || !pr.totalContractMin))
        || this.getTotals().totalValidRecipients < 1
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>
