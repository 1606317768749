<template>
  <ul class="list-reset inline-block">
    <li
      v-for="(item, i) in value"
      :key="i"
      class="text-xs border rounded-full py-1 px-2 mt-1
      inline-flex items-center border-green bg-green-lightest text-green"
      :class="[{ 'mr-1' : i < value.length-1 }]"
    >
      {{ item.match(/MSISDN/i) ? 'Mobile' : item }}
      <i
        v-if="!isAtMin() && !isDeletable(item)"
        @click="removeItem(item, i)"
        class="el-icon-close ml-1 cursor-pointer hover:text-red"
      >
      </i>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'tag-list',

  props: {
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    disableDelete: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isDeletable(item) {
      return this.disableDelete.includes(item);
    },

    isAtMin() {
      return this.value.length <= this.min;
    },

    removeItem(item, i) {
      if (!this.isAtMin()) {
        this.value.splice(i, 1);

        // Trigger @change event
        this.$emit('change', item);
      }
    },
  },
};
</script>
