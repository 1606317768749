<template>
  <div class="voice-send-test-msg mt-6">
    <h5 class="text-black font-medium mb-1 text-sm">
      {{ $t('mcs.messages.voice.send_test[0]') }}
    </h5>
    <p class="text-xs mb-3 text-grey">
      {{ $t('mcs.messages.voice.send_test[1]') }}
    </p>
    <el-button
      type="primary"
      id="open-test-voice-message-modal"
      @click="openModal"
      size="small"
      :disabled="disabled"
      :loading="loading"
    >
      {{ $t('mcs.messages.voice.send_test[2]') }}
    </el-button>
    <el-dialog
      :visible="modalVisible"
      @open="onOpenModal"
      @close="onCloseModal"
      width="65%"
      top="60px"
     >
      <div slot="title">
        <h5 class="text-black font-medium mb-1 text-sm">
          {{ $t('mcs.messages.voice.send_test[3]') }}
        </h5>
        <p class="text-xs text-grey">
          {{ $t('column_labels.source') }}: {{selected.source}}
        </p>
      </div>
      <div
        v-if="modalVisible"
        class="test-voice-form flex mb-5 min-h-full"
      >
        <div class="flex-1 mr-20">
          <CaMessageBox
            @update-message="updateMessage"
            :message="form.message || ''"
            :text-limit="3000"
          />
          <div
            class="w-2/3"
            v-if="fields.length"
          >
            <h6 class="text-black font-medium mb-1 text-xs">
              {{ $t('mcs.messages.voice.send_test[4]') }}
            </h6>
            <p class="text-xs text-grey mb-3">
               {{ $t('mcs.messages.voice.send_test[5]') }}
            </p>
            <div
              class="flex mb-2"
              v-for="i in fields"
              :key="i"
            >
              <div class="w-1/4 w mr-10 pt-1">
                <span class="item-tag p-1">{{i}}</span>
              </div>
              <div class="w-3/4 flex-1">
                <el-input
                  v-model="dynamicFields[i]"
                  size="small"
                  :placeholder="$t('validations.enter_value')"
                />
              </div>
            </div>
          </div>
          <hr class="mx-0 my-10 h-px bg-grey w-1/2">
          <h6 class="text-black font-medium mb-2 text-xs label__asterisk">
            {{ $t('mcs.messages.voice.send_test[6]') }}
          </h6>
          <VoiceSource
            :source="form.dest"
            @update-source="updateData"
            :label="Boolean(true)"
          />
        </div>
        <div class="flex-1">
           <h5 class="text-black font-medium mb-5 text-sm">
            {{ $t('column_labels.parameters') }}
          </h5>
          <h6 class="text-black font-medium mb-2 text-xs">
            {{ $tc('column_labels.language', 2) }}
          </h6>
          <VoiceLanguages
            :language="form.language"
            :languages="languages"
            :profiles="profiles"
            @update-language="updateData"
            @update-profile="updateData"
            :label="Boolean(true)"
          />

          <h6 class="text-black font-medium mb-2 text-xs">
            {{ $tc('column_labels.voice_profile', 2) }}
          </h6>
          <VoiceProfiles
            :language="form.language"
            :profile="form.profile"
            :profiles="profiles"
            @update-profile="updateData"
          />
          <h6 class="text-black font-medium mb-2 text-xs">
            {{ $t('column_labels.speed') }}
          </h6>
          <VoiceSpeed
            :speed="form.speed"
            :label="Boolean(true)"
            @update-speed="updateData"
          />

          <h6 class="text-black font-medium mb-2 text-xs">
            {{ $t('column_labels.repetition') }}
          </h6>
          <VoiceRepetition
            :repetition="form.repetition"
            :label="Boolean(true)"
            :type="'select'"
            @update-repetition="updateData"
          />
        </div>
      </div>
      <div
        class="text-black text-xs p-3 bg-yellow-lightest
        border border-solid border-yellow rounded mb-5 w-1/2 leading-normal"
      >
         {{ $t('mcs.messages.voice.send_test[7]') }}
      </div>
      <el-button
        type="primary"
        id="send-test-voice-message"
        @click="handleSubmit"
        class="mr-3"
        size="small"
        :disabled="!form.message || !form.dest"
        :loading="loading"
      >
        {{ $t('mcs.messages.voice.send_test[2]') }}
      </el-button>
      <el-button
        id="cancel-test-voice-message"
        @click="handleCancel"
        size="small"
      >
        {{ $t('actions.cancel') }}
      </el-button>
    </el-dialog>
</div>
</template>
<script>
import { hasBrackets, removeBrackets, factorPascalCasedWords } from '@/utils/common';

import VoiceSource from './Source.vue';
import VoiceLanguages from './Languages.vue';
import VoiceSpeed from './Speed.vue';
import VoiceRepetition from './Repetition.vue';
import VoiceProfiles from './Profiles.vue';
import CaMessageBox from '../ChatApp/MessageBox.vue';

export default {
  name: 'message_voice_send_test_msg',

  components: {
    VoiceSource,
    VoiceLanguages,
    VoiceSpeed,
    VoiceRepetition,
    VoiceProfiles,
    CaMessageBox,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    selected: {
      type: Object,
      default: () => ({}),
    },

    variables: {
      type: Object,
      default: () => [],
    },

    languages: {
      type: Object,
      default: () => [],
    },

    profiles: {
      type: Object,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        dest: '',
        message: '',
        language: {
          name: 'English (United States)',
          code: 'en-US',
        },

        profile: {
          type: Object,
          default: () => ({
            code: 'en-US',
            profile: 'en-US-AriaRUS',
          }),
        },
        speed: 1,
        repetition: 1,
      },

      dynamicFields: {},

      modalVisible: false,
    };
  },

  computed: {
    fields() {
      const { form, variables } = this;
      let f = [];
      const b = hasBrackets(form.message);

      if (b && b.length && variables.length) {
        f = b.reduce((a, k) => {
          const v = k.replace('{{', '').replace('}}', '');

          if (variables.includes(v)) {
            a.push(v);
          }
          return a;
        }, []);
      }

      return f;
    },
  },

  methods: {
    onOpenModal() {
      this.reset();
      this.update();
    },

    onCloseModal() {
      this.reset();
      this.modalVisible = false;
    },

    openModal() {
      this.modalVisible = true;
    },

    handleCancel() {
      this.modalVisible = false;
    },

    handleSubmit() {
      const {
        language,
        repetition,
        speed,
        message,
        dest,
        profile,
      } = this.form;

      const { source } = this.selected;
      const valid = dest && message;

      if (valid) {
        const payload = {
          language,
          repetition,
          speed,
          profile,
          prettified: dest.trim(),
          destination: dest.trim().replace(/\s+/g, ''),
          source: source.trim().replace(/\s+/g, ''),
          text: this.replaceDynamicFields(message),
        };

        this.$emit('send-test-message', payload);
      }
    },

    reset() {
      this.form = {
        dest: '',
        language: {
          name: 'English (United States)',
          code: 'en-US',
        },
        profile: {
          code: 'en-US',
          profile: 'en-US-AriaRUS',
        },
        speed: 1,
        message: '',
        repetition: 1,
      };
      this.dynamicFields = {};
    },

    update() {
      const {
        language,
        speed,
        message,
        repetition,
        profile,
      } = this.selected;

      this.form.language = language;
      this.form.speed = speed;
      this.form.repetition = repetition;
      this.form.message = message;
      this.form.profile = profile;
    },

    replaceDynamicFields(message) {
      const { variables } = this;
      let m = message;
      const b = hasBrackets(message);

      if (b && b.length) {
        b.forEach((val) => {
          const v = removeBrackets(val);
          if (variables.includes(v)) {
            if (this.dynamicFields[v]) {
              m = m.replace(`{{${v}}}`, this.dynamicFields[v]);
            } else {
              m = m.replace(`{{${v}}}`, factorPascalCasedWords(v));
            }
          }
        });
      }

      return m;
    },

    updateMessage(message) {
      this.form.message = message;
    },

    updateData(value, key) {
      let k = key;

      if (key === 'source') {
        k = 'dest';
      }

      this.form[k] = value;
    },
  },

};
</script>
<style lang="scss">
  .test-voice-form {
    .w-2\/3 {
      width: 100%;
    }

    .slider {
      width: 75%;
    }

    .voice-speed {
      .w-1\/2 {
        width: 70%;
      }
    }

    .voice-repetition {
      .el-select {
        width: 50%;
      }
    }
  }
</style>
