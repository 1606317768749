<template>
  <div class="summary-card flex flex-col items-stretch bg-white rounded">
    <div class="summary-card__body border border-grey-light bg-grey-lighter rounded
      bg-grey-lightest p-4 h-full">
      <slot name="body"></slot>
    </div>
    <div
      v-if="$slots.footer"
      class="summary-card__footer border border-t-0 border-grey-light rounded-b text-center p-4
      -mt-1 pt-3 pb-2 text-xs">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'summary-card',
};
</script>
