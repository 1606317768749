<template>
  <div class="custom-fields w-full">
    <div
      v-if="true"
      class="border border-grey-light bg-grey-lighter p-3 rounded-sm flex justify-between
      text-xs w-full items-center"
    >
      <div>
        <p class="text-grey-dark">{{ $t('mcs.recip.summary[0]') }}</p>
        <p class="text-black mt-2">
          {{ form.Recipients.FileName }}
          <strong v-if="form.Recipients.FileSize">
            ({{ form.Recipients.FileSize }})
          </strong>
        </p>
      </div>
      <div>
        <button
          class="bg-white hover:bg-blue-lightest hover:text-blue p-3 rounded-sm rounded
          text-grey-darkest mr-2"
          @click.prevent="discardUpload"
        >
          {{ $t('mcs.recip.summary[1]') }}
        </button>
      </div>
    </div>

    <div>
      <div class="my-5">
        <h3 class="mb-2 text-sm text-black tracking-normal">{{ $t('mcs.recip.set_fields[0]') }}</h3>
        <p class="text-xs text-grey-dark leading-tight">
          {{  $t('mcs.recip.set_fields[1]') }}
        </p>
      </div>
      <div>
        <CustomFieldsTable
          class="mb-10"
          :loading="processLoading"
          :headers="getColumnIndex"
          :header-values="colValues"
          :custom-field-options="customFieldsOptions"
          :options="originalCustomFieldsOptions"
          :data="tableData"
          :custom-field-input="newCustomField"
          @update-column="updateColumnValue"
          @remove-column="removeCustomField"
          @add-custom-field-name="addCustomField"
        />
        <div class="mt-5 flex -mx-2">
          <div
            v-if="form.Type !== 'VOICE'"
            class="px-1 w-1/2">
            <div class="border border-grey-light bg-grey-lighter p-3 rounded-sm text-xs">
              <h3 class="text-sm text-black tracking-normal">{{  $t('mcs.recip.set_fields[2]') }}</h3>
              <el-switch
                v-model="allowDuplicates"
                :disabled="processLoading"
                class="block my-3"
                active-color="#14CD65"
                :active-text="$t('mcs.recip.set_fields[13]')"
                @change="v => updateRecipients({ AllowDuplicates: v })"
              >
              </el-switch>
              <p class="text-xs text-grey-dark leading-tight">{{  $t('mcs.recip.set_fields[3]') }}</p>
            </div>
          </div>
          <transition name="fade-up" mode="out-in">
            <div
              :key="showNumberFormatOptions"
              v-if="showNumberFormatOptions"
              class="px-1 w-1/2"
              >
              <div class="border border-grey-light bg-grey-lighter rounded-sm text-xs">
                <div class="p-3">
                  <div
                    v-if="!country"
                    class="border border-red-lightest bg-red text-white
                    p-3 flexitems-center rounded mb-3 flex items-center">
                    <i class="el-icon-warning-outline mr-2 text-xl block"></i>
                    <span class="text-xs leading-tight block">
                      {{  $t('mcs.recip.set_fields[4]') }}
                    </span>
                  </div>
                  <h3 class="text-sm text-black tracking-normal">
                    {{ $t('mcs.recip.set_fields[5]') }}
                  </h3>
                  <el-select
                    v-model="country"
                    :disabled="checkDisableCountryCode() || processLoading"
                    size="small"
                    class="w-full py-3"
                    :placeholder="$t('mcs.recip.set_fields[5]')"
                    filterable
                    clearable
                    @change="v => updateRecipients({ Location: v })"
                    >
                    <template slot="prefix">
                      <div v-if="country" class="flex items-center h-full">
                        <span
                          class="border-grey-light flag-icon"
                          :class="[`flag-icon-${country.toLowerCase()}`]"/>
                      </div>
                    </template>
                    <el-option
                      v-for="item in countriesJson"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                      <span>
                        <span
                          class="mr-2 border-grey-light flag-icon"
                          :class="[`flag-icon-${item.value.toLowerCase()}`]"/>
                          {{ item.name }}
                        </span>
                        <span class="text-grey text-xs">
                          ({{ item.callingCode }})
                        </span>
                    </el-option>
                  </el-select>
                  <p class="text-xs text-grey-dark leading-tight">
                  {{ $t('mcs.recip.set_fields[6]') }}
                  </p>
                </div>
                <div class="p-3 bg-white border-top border-grey-lighter">
                  <el-switch
                    v-model="allNumbersLocal"
                    :disabled="processLoading"
                    class="block mb-3"
                    active-color="#14CD65"
                    :active-text="$t('mcs.recip.set_fields[7]')"
                    @change="v => updateRecipients({ AllNumbersLocal: v })"
                    >
                  </el-switch>
                  <p
                    v-if="country"
                    class="text-xs text-grey-dark leading-tight flex">
                     {{ $t('mcs.recip.set_fields[8]') }}
                    <transition name="slide-left" mode="out-in">
                    <strong :key="country">
                      &nbsp;{{ countriesJson.find(c => c.value === country).name }}
                    </strong>
                    </transition>
                  </p>
                  <p v-else class="text-xs text-grey-dark leading-tight flex">
                     {{ $t('mcs.recip.set_fields[9]') }}
                  </p>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <el-button
        @click="$emit('discard')"
      >
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        :loading="processLoading"
        type="primary"
        :disabled="!isValid()"
        @click="submit()"
        id="mcs-set-custom-fields"
      >
        {{ processLoading ? $t('wait.processing') :  $t('mcs.recip.set_fields[10]') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';
import { mapGetters, mapMutations } from 'vuex';
import countriesJson from '@/json/countries.json';
import CustomFieldsTable from './CustomFieldsTable.vue';

export default {
  name: 'set-custom-fields',

  components: {
    CustomFieldsTable,
  },

  watch: {
    uploadDataSummary(newVal) {
      this.setSummary({ summary: newVal });
      this.$emit('submit-uploads', newVal);
    },
  },

  data() {
    const originalCustomFieldsOptions = ['MSISDN', 'FirstName', 'LastName', 'ClientMessageId'];

    return {
      countriesJson,
      newCustomField: '',
      colValues: [],
      customFields: {},
      originalCustomFieldsOptions,
      customFieldsOptions: originalCustomFieldsOptions,
      country: '',
      allowDuplicates: false,
      allNumbersLocal: false,
      tableData: [],
    };
  },

  props: {
    form: {
      type: Object,
      default: () => {},
    },

    partialData: {
      type: Object,
      default: () => {},
    },

    processUpload: {
      type: Function,
      default: () => {},
    },

    socketId: {
      type: String,
      default: '',
    },
    queueUrl: {
      type: String,
      default: '',
    },
  },

  created() {
    let partialDataList = [];
    // Check if paritalData.List is null
    if (this.partialData && this.partialData.List) {
      partialDataList = [...this.partialData.List];
    }
    const data = partialDataList;

    this.tableData = data;
    this.colValues = this.tableData.length ? new Array(Object.keys(this.tableData[0]).length) : [];

    // Set default values
    this.country = this.form.Recipients.Location || this.country;
    this.allowDuplicates = this.form.Recipients.AllowDuplicates || this.allowDuplicates;
    this.allNumbersLocal = this.form.Recipients.AllNumbersLocal || this.allNumbersLocal;

    if (this.form.Recipients.columnLabels) {
      const colLabels = this.form.Recipients.columnLabels;
      this.customFieldsOptions = Object.keys(colLabels).map(label => {
        return {label, id: label, isEdit: false}
      });
      Object.keys(colLabels).forEach((label) => {
        const index = parseInt(colLabels[label], 10);
        if (index >= 0) {
          this.colValues[index] = label;
        }
      });

      this.customFields = this.form.Recipients.columnLabels;
    } else {
      // Set all column to -1
      this.customFields = this.customFieldsOptions.reduce((obj, item) => ({
        ...obj,
        [item]: -1,
      }), {});
    }


    // Update form with default values
    this.$emit('update-form', {
      Recipients: {
        ...this.form.Recipients,
        Location: this.country,
        AllowDuplicates: this.allowDuplicates,
        AllNumbersLocal: this.allNumbersLocal,
        columnLabels: { ...this.customFields },
      },
    });
  },

  computed: {
    ...mapGetters({
      uploadDataSummary: 'websender/getUploadData',
      processLoading: 'websender/getProcessesing',
    }),

    showNumberFormatOptions() {
      const phoneIndex = this.form.Recipients.columnLabels.MSISDN;
      let isNum = true;
      const invalidNums = [];
      if (phoneIndex >= 0) {
        this.tableData.forEach((data) => {
          const num = data[phoneIndex];
          const n = num.replace(/^(\+){1}/g, '').replace(/ /g, '');
          const checkNum = n[0] === '0' ? n : `+${n}`;
          const isValidLength = /[0-9]{7,20}/.test(n);
          isNum = n.match(/^[0-9]+$/) != null;

          try {
            const pn = parsePhoneNumber(checkNum);

            // If INVALID number, check if its valid in length
            // IF VALID, do nothing
            if (!pn.valid) {
              // check if its a number
              // If not a number, do nothing
              if (isNum && isValidLength) {
                invalidNums.push(n);
              }
            }
          } catch (e) {
            // If !isNum or !isValidLegth, do nothing
            if (e && isNum && isValidLength) {
              invalidNums.push(n);
            }
          }
        });
      }
      return invalidNums.length;
    },

    getColumnIndex() {
      return this.tableData.length ? Object.keys(this.tableData[0]) : [];
    },
  },

  methods: {
    ...mapMutations({
      setSummary: 'recipientsContacts/SET_SUMMARY',
      setProcessing: 'websender/SET_PROCESSING',
    }),

    isValid() {
      return this.colValues.includes('MSISDN');
    },

    async submit() {
      if (!this.isValid()) {
        // this.$message.error(this.$t('mcs.recip.set_fields[11]'));
        this.$notify.error({
          title: this.$t('mcs.recip.set_fields[11]'),
        });
        return;
      }

      const payload = Object
        .assign({}, this.partialData, {
          Column: this.form.Recipients.columnLabels,
          SocketId: this.socketId,
          QueueURL: this.queueUrl,
          AllowDuplicates: this.form.Recipients.AllowDuplicates,
          Country: this.form.Recipients.Location || null,
          AllNumbersLocal: this.form.Recipients.AllNumbersLocal,
        });

      this.setProcessing(true);

      try {
        await this.processUpload(payload);
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error(err.message || 'Unable process uploaded file');
        this.setProcessing(false);
      }
    },

    checkDisableCountryCode() {
      return 0;
      // return !this.hasLocalNumber || (typeof this.uploadData === 'object' && typeof this.file ===
      //   'object') || this.processing;
    },

    getColumnValue(index) {
      const colVal = Object.keys(this.customFields).find(cf => cf === index);
      return colVal || -1;
    },

    updateColumnValue({ val = 0, i = 0 }) {
      this.customFields[val] = i;

      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          columnLabels: { ...this.customFields },
        },
      });
    },


    addCustomField(val) {
      const s = val.replace(/\s+/g, '');
      this.newCustomField = '';
      if (!this.customFieldsOptions.includes(s) && !s.match(/mobile/i)) {
        this.customFieldsOptions = [...this.customFieldsOptions, {id:s, label: s, isEdit: false}];
      } else {
        // this.$message.error(this.$t('mcs.recip.set_fields[12]'));
        this.$notify.error({
          title: this.$t('mcs.recip.set_fields[12]'),
        });
      }
    },

    removeCustomField({ val = 0, i = 0 }) {
      this.colValues.splice(i, 1, null);
      this.customFields = { ...this.customFields, [val]: -1 };

      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          columnLabels: { ...this.customFields },
        },
      });
    },

    discardUpload() {
      this.$confirm(this.$t('mcs.recip.summary[18]'), this.$t('actions.edit'), {
        confirmButtonText: this.$t('actions.discard'),
        cancelButtonText: this.$t('actions.cancel'),
        type: 'warning',
      }).then(() => {
        this.$emit('reset-upload');
        this.$emit('reset-contacts');
        this.$emit('change-step', 'ADD_RECIPIENT');
      });
    },

    updateRecipients(obj) {
      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          ...obj,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.custom-fields {
  .el-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
  }

  .el-select {
    input {
      line-height: 0 !important;
    }
  }

  .fade-up-enter-active {
    transition: all 0.2s ease;
  }

  .fade-up-leave-active {
    transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
}

.custom-field-table {
  &.el-table--scrollable-x {
    .el-table__body-wrapper {
      overflow-x: hidden !important;
    }
  }
}
</style>
