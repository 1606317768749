var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 msg-preview"},[_c('div',{staticClass:"bg-grey-lightest border border-solid border-grey-light rounded-lg"},[_c('div',{staticClass:"px-5",class:[_vm.hideLabel || 'pt-5']},[(!_vm.hideLabel)?_c('p',{staticClass:"text-black font-medium mb-3"},[_vm._v(_vm._s(_vm.$t('mcs.message_preview')))]):_vm._e(),(_vm.messageType.match(/SMS/))?_c('div',{staticClass:"text-grey-darker mb-5 leading-normal min-w-full text-xs whitespace-pre-wrap break-words max-h-full prev-content"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),(_vm.messageType === 'VOICE')?_c('div',{staticClass:"text-grey-darker mb-5 leading-normal min-w-full text-xs whitespace-pre-wrap break-words max-h-full prev-content",domProps:{"innerHTML":_vm._s(_vm.getContentMsg(_vm.message, _vm.variables, _vm.customFields))}}):_vm._e(),(_vm.messageType === 'CHAT_APPS')?_c('div',{class:[
          { 'shadow border rounded mb-5 border-grey-lighter':  Object.keys(_vm.header).length },
        ]},[(_vm.channel.match(/WA/i))?_c('div',[(Object.keys(_vm.header).length)?_c('div',{staticClass:"text-grey-dark bg-white rounded-t"},[(_vm.header.type !== 'text')?_c('div',{staticClass:"text-center"},[(_vm.header.type === 'image')?_c('div',{staticClass:"w-full"},[_c('ImageDisplay',{attrs:{"file-url":_vm.getFileUrl(_vm.customFields),"is-preview":Boolean(true)}})],1):_c('div',{staticClass:"py-6 px-2"},[_c('img',{staticClass:"align-middle",style:({width: '40px', height: '40px'}),attrs:{"src":_vm.urlResolver(
                      _vm.bgUrl,
                      require(`@/assets/media/${_vm.getMediaIcons(_vm.header.type, _vm.customFields)}.svg`)
                    ),"alt":_vm.header.type}})]),(_vm.header.type === 'location'
                  && _vm.getLocationFields(_vm.customFields).name
                  && _vm.getLocationFields(_vm.customFields).address
                )?_c('div',{staticClass:"text-left bg-grey-shade-custom py-2"},[_c('span',{staticClass:"p-1 ml-2 text-xs block"},[_vm._v(_vm._s(_vm.getLocationFields(_vm.customFields).name))]),_c('span',{staticClass:"text-grey-dark p-1 ml-2 text-xs block"},[_vm._v(_vm._s(_vm.getLocationFields(_vm.customFields).address))])]):_vm._e()]):_c('div',{staticClass:"py-4 text-sm text-black font-semibold",domProps:{"innerHTML":_vm._s(_vm.replaceHeaderField(_vm.header.value, _vm.customFields, _vm.variables))}})]):_vm._e(),_c('div',{class:[
              { 'px-2' : Object.keys(_vm.header).length },
              'min-w-full py-4',
              'text-xs',
              'max-h-full overflow-auto',
            ]},[_c('div',{staticClass:"prev-content whitespace-pre-wrap break-words text-grey-darker leading-tight",domProps:{"innerHTML":_vm._s(_vm.getContentMsg(_vm.message, _vm.variables, _vm.customFields))}}),(_vm.footer)?_c('div',{staticClass:"mt-5 text-grey-dark",style:({fontSize: '10px'})},[_vm._v("\n              "+_vm._s(_vm.footer)+"\n            ")]):_vm._e(),(_vm.hasCTAButtons())?_c('div',{staticClass:"mt-3 border-t solid border-grey-light p-2 text-grey-dark text-base text-center"},_vm._l((_vm.waButtons),function(btn,i){return _c('div',{key:i,staticClass:"py-2"},[(btn.type.toLowerCase() === 'phone_number')?_c('div',[_c('a',{staticClass:"text-blue hover:text-blue-light no-underline",attrs:{"href":`tel:${btn.phoneNumber}`,"target":"_blank","rel":"nofollow noopener"}},[_c('img',{staticClass:"align-middle",style:({height: '15px'}),attrs:{"src":_vm.urlResolver(
                        _vm.bgUrl,
                        require(`@/assets/misc/phone.svg`)
                      )}}),_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(btn.text)+" ")])])]):_vm._e(),(btn.type.toLowerCase() === 'url')?_c('div',[_c('a',{staticClass:"text-blue hover:text-blue-light no-underline",attrs:{"href":`${_vm.replaceURLSuffix(btn.url, _vm.customFields, _vm.variables)}`,"target":"_blank","rel":"nofollow noopener"}},[_c('img',{staticClass:"align-middle",style:({height: '15px'}),attrs:{"src":_vm.urlResolver(
                        _vm.bgUrl,
                        require(`@/assets/misc/external-link.svg`)
                      )}}),_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(btn.text)+" ")])])]):_vm._e()])}),0):_vm._e(),(_vm.hasQRButtons())?_c('div',{staticClass:"mt-3 py-2"},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.waButtons),function(btn,i){return _c('div',{key:i,staticClass:"quick-btn px-1"},[_c('el-button',{staticClass:"w-full block bg-white shadow rounded mb-3 text-blue border border-transparent",attrs:{"size":"small"}},[_vm._v("\n                    "+_vm._s(btn.text)+"\n                  ")])],1)}),0)]):_vm._e()])]):_c('div',[(_vm.message)?_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"leading-normal min-w-full text-xs prev-wrapper whitespace-pre-wrap break-words max-h-full",domProps:{"innerHTML":_vm._s(_vm.getContentMsg(_vm.message, _vm.variables, _vm.customFields))}})]):_vm._e(),(_vm.imageInputs && Object.keys(_vm.imageInputs).length)?_c('div',{staticClass:"image text-center py-2"},[(_vm.imageInputs.previewUrl.match(/\/s3\/uploads/))?_c('div',{ref:"imageInput"}):_c('img',{attrs:{"src":_vm.imageInputs.previewUrl,"alt":_vm.imageInputs.previewUrl}})]):_vm._e(),(_vm.buttonInputs && Object.keys(_vm.buttonInputs).length)?_c('div',{class:[
              'button text-center px-4 py-2',
            ]},[_c('a',{staticClass:"bg-white rounded-full py-2 px-4 text-sm font-medium no-underline shadow border solid border-grey-lighter block",class:[{
                'bg-purple border-transparent text-white hover:bg-purple-light'
                : _vm.channel.match(/VB/i)
              }],attrs:{"href":_vm.buttonInputs.url,"rel":"noopener noreferrer","target":"_blank"}},[_vm._v("\n              "+_vm._s(_vm.buttonInputs.title)+"\n            ")])]):_vm._e()])]):_vm._e()]),(_vm.messageType.match(/SMS/))?_c('div',{staticClass:"bg-white px-5 py-3 rounded-b-lg flex"},[_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"text-sm text-grey-dark mb-2"},[_vm._v(_vm._s(_vm.$t('column_labels.sms_parts')))]),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(Number(_vm.smsParts).toLocaleString()))])]),_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"text-sm text-grey-dark mb-2"},[_vm._v(_vm._s(_vm.$t('column_labels.total_sms')))]),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(Number(_vm.totalSms).toLocaleString()))])])]):_vm._e(),(_vm.messageType.match(/VOICE|CHAT_APPS/i) && !_vm.hideTotalMessage)?_c('div',{staticClass:"bg-white px-5 py-3 rounded-b-lg"},[_c('p',{staticClass:"text-sm text-grey-dark mb-2"},[_vm._v(_vm._s(_vm.$t('column_labels.total_msgs')))]),_c('span',{staticClass:"font-semibold"},[_vm._v("\n        "+_vm._s(_vm.messageType === 'VOICE' ?
          Number(_vm.totalVoiceMsgs || 0).toLocaleString() :
          Number(_vm.totalCaMsgs || 0).toLocaleString())+"\n      ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }