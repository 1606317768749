<template>
  <div>
    <div class="px-5">
      <p class="text-grey-dark text-sm mb-2">{{ $t("column_labels.total_msgs") }}</p>
      <p class="text-black text-base font-bold">{{ totalMessages.toLocaleString() }}</p>
    </div>
    <p class="flex items-center mb-4 mx-5 w-1/2">
      <i class="el-icon-bottom text-2xl"></i>
      <span class="text-grey-dark text-xs ml-2">
        {{ $t("mcs.campaigns.fallback_details[0]") }}
      </span>
    </p>
    <div v-if="Object.keys(campaignMeta.channels || []).length">
      <div v-for="(channel, index) in campaignMeta.channels" :key="index" class="mt-4">
        <div class="flex mb-3 p-5 border border-grey-light rounded-lg">
          <div class="w-1/2">
            <div class="flex items-center">
              <img :src="getChannelIcon(channel)" />
              <span class="text-black text-sm ml-2">
                {{ channelByCode(channel).name }}
              </span>
              <span class="text-grey-dark text-xs ml-4">
                {{
                  `${((channelData[channel] || []).hasOwnProperty("total")
                    ? channelData[channel].total
                    : 0
                  ).toLocaleString()} ${$t("mcs.campaigns.fallback_details[2]")}`
                }}
              </span>
            </div>
            <ProgressBar
              :data="
                (channelData[channel] || []).hasOwnProperty('chartData')
                  ? channelData[channel].chartData
                  : []
              "
              :total="totalMessages"
              class="mt-4 mr-8"
            />
          </div>
          <div
            v-if="(channelData[channel] || []).hasOwnProperty('chartData')"
            class="w-1/2 flex items-center"
          >
            <template v-for="item in channelData[channel].chartData">
              <div v-if="item.label" class="w-1/2" :key="item.value">
                <p class="text-grey-dark text-xs mb-2 flex items-center mr-1">
                  <svg width="12" height="6" class="mr-1">
                    <rect width="12" height="6" rx="3" :style="{ fill: item.color }" />
                  </svg>
                  {{ item.label }}
                </p>
                <p class="text-black text-base font-bold">
                  {{ item.value.toLocaleString() }}
                </p>
                <p class="text-sm font-normal mt-2">
                  ({{ computeValuePercentage(item, channelData[channel].total), }})
                </p>
              </div>
            </template>
          </div>
        </div>
        <!-- here -->
        <p
          v-if="(index + 1) != (campaignMeta.channels).length"
          class="flex items-center mb-4 mx-5 w-1/2"
        >
          <i class="el-icon-bottom text-2xl"></i>
          <i18n
            path="mcs.campaigns.fallback_details[1]"
            tag="span"
            class="text-grey-dark text-xs ml-2"
          >
            <template #seconds>
              <strong class="text-black">{{ getFallbackDuration(channel) }} </strong>,&nbsp;
            </template>
          </i18n>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue';
import CampaignListMixin from '@/mixins/campaign-list';
import { getHumanReadable } from '@/utils/common';
import { mapGetters } from 'vuex';

export default {
  name: 'fallback-details',

  components: {
    ProgressBar,
  },

  mixins: [CampaignListMixin],

  props: {
    data: {
      type: Object,
      required: true,
    },

    campaignMeta: {
      type: Object,
      default: () => {},
    },
    subAccount: {
      type: String,
      required: true,
    },
  },

  data() {
    const { totalSent } = this.data.summary;

    return {
      totalMessages: totalSent,
      channelData: {},
    };
  },

  computed: {
    ...mapGetters({
      getSubAccounts: 'user/getSubAccounts',
    }),
  },

  created() {
    const self = this;
    // Populate channelData Map
    this.data.channels.forEach((channel) => {
      const {
        ChannelType: channelType,
        MsgDelivered: deliveredValue,
        MsgRead: readValue,
        MsgRejected: rejectedValue,
        MsgUndelivered: undeliveredValue,
        MsgTotal: total,
      } = channel;

      this.channelData[channelType] = {
        total,
        chartData: [
          {
            label: self.$t('column_labels.delivered'),
            value: deliveredValue,
            color: '#403a65',
          },
          {
            label: channelType !== 'SM' ? 'Read' : '',
            value: readValue,
            color: '#10b981',
          },
          {
            label: self.$t('column_labels.undelivered'),
            value: undeliveredValue,
            color: '#f59e0b',
          },
          {
            label: self.$t('column_labels.rejected'),
            value: rejectedValue,
            color: '#dc2626',
          },
        ],
      };
    });
  },

  methods: {
    getFallbackDuration(ChannelType) {
      const config = this.getSubAccounts.filter(sa => sa.SubAccountId === this.subAccount)[0]
        .FallbackConfig;
      if (config) {
        const channelConf = config.filter(conf => conf.ChannelType === ChannelType);
        if (channelConf.length) return getHumanReadable(channelConf[0].FallbackDelaySec);
        return '50 seconds';
      }
      return '50 seconds';
    },

    computeValuePercentage(data, total = 0) {
      return this.toPercentString((data.value / (total || 1)) * 100);
    },

    toPercentString(n) {
      return `${n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}%`;
    },
  },
};
</script>
