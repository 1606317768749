<!-- eslint-disable no-param-reassign -->
<!-- eslint-disable no-param-reassign -->
<!-- eslint-disable no-param-reassign -->
<template>
  <div class="">
    <div class="flex px-3 py-2">
      <div class="w-3/5 flex">
        <el-input v-model="searchString"  class="inline-block flex-auto" :placeholder="$t('mcs.campaigns.table[1]')"
          :disabled="loading"
          prefix-icon="icon-novo-search" @change="handleSearch" />
      </div>
      <div class="px-5 flex-auto flex items-center">
        <el-badge class="filter" :value="filterCount" type="warning" :hidden="!filterCount">
          <span class="cursor-pointer icon-novo-filter text-2xl p-1 hover-icon" @click="isMoreFilterVisible = !isMoreFilterVisible" />
        </el-badge>
        <span class="ml-6 text-base">
           {{campaigns.length.toLocaleString()}} {{$t('app_labels.results')}}
        </span>
      </div>
      <div class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
        <el-tooltip
          :content="$t('actions.refresh')"
          placement="top">
          <span class="icon-novo-refresh text-2xl cursor-pointer hover-icon" @click="$emit('reload-filter')"/>
        </el-tooltip>
        <el-tooltip
          :content="$t('actions.full_download')"
          placement="top">
          <span class="ml-5 icon-novo-download text-2xl cursor-not-allowed hover-icon text-grey" />
        </el-tooltip>
      </div>
    </div>
    <div v-if="isMoreFilterVisible" class="flex py-3 border-t border-grey-lighter px-3">
      <div class="flex-1">
        <span class="text-xs block mb-2">
          {{ $t('column_labels.date_range') }}{{ tz.timeZone }}
        </span>
        <CustomPicker
          :show-tz="false"
          :loading="loading"
          :user="user"
          :date-range="dateRange"
          @change="dateRangeHandler" />
      </div>
      <div class="flex-1 ml-2">
        <span class="text-xs block mb-2">
          {{ $tc('column_labels.channel', 2) }}
        </span>
        <el-select
          :value="filters.channel"
          :disabled="loading"
          class="w-full"
          filterable
          size="small"
          @change="v => updateFilter({ channel: v })"
          >
          <el-option
            v-for="channel in [{ name: $t('column_labels.all_channels'), campaignCode: '' }, ...channelList]"
            :key="channel.campaignCode"
            :label="channel.name"
            :value="channel.campaignCode"
            >
          </el-option>
        </el-select>
      </div>
      <div class="flex-1 ml-2">
        <span class="text-xs block mb-2">
          {{ $t('column_labels.status') }}
        </span>
        <el-select
          :value="filters.status"
          :disabled="loading"
          class="w-full"
          filterable
          size="small"
          @change="v => updateFilter({ status: v })"
          >
          <el-option
            v-for="status in [{ name: $t('column_labels.all_status'), value: '' }, ...statusList]"
            :key="status.value"
            :label="status.name"
            :value="status.value"
            >
          </el-option>
        </el-select>
      </div>
      <div class="flex-shrink flex items-center justify-center px-4 py-2">
        <a :disabled="loading"  href="#" class="no-underline text-sm mt-1" @click.prevent="resetFilter()">{{ $t('actions.clear_all') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import CustomPicker from '../../../components/CustomPicker.vue';

export default {
  name: 'FilterNew',

  components: {
    CustomPicker,
  },

  props: {
    filters: {
      default: () => { },
      type: Object,
    },

    defaultFilters: {
      default: () => { },
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    channelList: {
      default: () => [],
      type: Array,
    },
    statusList: {
      default: () => [],
      type: Array,
    },
    defaultDaterange: {
      default: () => [],
      type: Array,
    },

    user: {
      type: Object,
      default: () => { },
    },

    campaigns: {
      default: () => [],
      type: Array,
    },
  },

  data() {
    return {
      dates: [],
      dateRange: {},
      isMoreFilterVisible: false,
      searchString: '',
      countedFilters: ['daterange', 'channel', 'status'],
    };
  },

  computed: {
    ...mapGetters({
      tz: 'user/getTz',
    }),

    filterCount() {
      const getDateStr = (date1, date2) => {
        const dateRange1 = Moment(date1).format('MM/DD/YYYY');
        const dateRange2 = Moment(date2).format('MM/DD/YYYY');

        return `${dateRange1}-${dateRange2}`;
      };

      const filters = this.countedFilters.reduce((a, b) => {
        if (Object.keys(this.defaultFilters).some(f => f === b)) {
          // eslint-disable-next-line no-param-reassign
          a.default[b] = this.defaultFilters[b];
          // eslint-disable-next-line no-param-reassign
          a.touched[b] = this.filters[b];

          if (b === 'daterange') {
            // eslint-disable-next-line no-param-reassign
            a.default.daterange = getDateStr(this.defaultFilters[b][0], this.defaultFilters[b][1]);
            // eslint-disable-next-line no-param-reassign
            a.touched.daterange = getDateStr(this.filters[b][0], this.filters[b][1]);
          }
        }

        return a;
      }, { default: {}, touched: {} });


      const affectedFilters = Object.keys(filters.default).reduce((a, b) => {
        if (filters.default[b] !== filters.touched[b]) {
          a.push(b);
        }

        return a;
      }, []);


      return affectedFilters.length;
    },
  },

  watch: {
    'filters.daterange': {
      handler(value) {
        this.dateRange = { from: value[0], to: value[1] };
      },
    },
  },

  created() {
    this.dates = this.defaultDaterange;
    this.dateRange = { from: this.dates[0], to: this.dates[1] };
  },

  methods: {
    updateFilter(v) {
      this.$emit('update-filter', v);
    },

    resetFilter() {
      const defaultFilters = this.countedFilters.reduce((a, b) => {
        if (Object.keys(this.defaultFilters).some(f => f === b)) {
          // eslint-disable-next-line no-param-reassign
          a[b] = this.defaultFilters[b];
        }

        return a;
      }, {});

      this.$emit('update-filter', { ...this.filters, ...defaultFilters, page: 1 });
    },

    handleSearch() {
      if (!this.searchString.length) {
        delete this.filters.campaignName;

        this.$emit('update-filter', { ...this.filters });
        return;
      }
      this.$emit('update-filter', { ...this.filters, campaignName: this.searchString.toUpperCase() });
    },

    dateRangeHandler(d) {
      this.dates[0] = Number(Moment(d.dateFrom).startOf('day'));
      this.dates[1] = Number(Moment(d.dateTo).endOf('day'));

      this.$emit('update-filter', {
        daterange: this.dates,
        tz: d.tz,
      });
    },

    hasMCSRole() {
      const userRoles = _.get(this, 'user.Roles', []);

      return userRoles.includes('MultichannelSender_v2');
    },
  },
};
</script>

<style scoped lang="scss">
.sender-datepicker {
  width: 100% !important;
}
</style>
