<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    @submit.prevent.native
    class="voice-source mb-5"
  >
    <div class="w-2/3">
      <div v-if="!label">
        <h5 class="text-black font-medium mb-2 text-sm label__asterisk">
          {{ $t('column_labels.source') }}
        </h5>
        <!-- <p class="mb-3 text-grey-dark text-xs">
          Identify yourself or your company by selecting the call origin or
          from <br/> existing origin templates.
        </p> -->

        <i18n
          path="mcs.messages.voice.source[0]"
          tag="p"
          class="mb-3 text-grey-dark text-xs"
        >
          <template #br>
            <br>
          </template>
        </i18n>
      </div>
      <el-form-item prop="source">
        <div class="relative w-3/4">
          <!-- <vue-tel-input
            v-model="form.source"
            required
            :input-options="{showDialCode: true}"
            class="voice-source__tel-input mt-2 text-sm text-grey-dark"
            @input="handlePhoneValidate"
          /> -->
          <VueTelInput
            v-model="form.source"
            :input-options="{showDialCode: true, placeholder: $t('user.enter_mobile')}"
            :disabled="loading"
            required
            class="voice-source__tel-input mt-2"
            @input="handlePhoneValidate"
          >
            <template v-slot:arrow-icon>
              <i class="el-icon-caret-bottom" />
            </template>
          </VueTelInput>
        </div>
      </el-form-item>
    </div>
  </el-form>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'message_voice_source',

  components: {
    VueTelInput,
  },

  props: {
    source: {
      type: String,
      default: '',
    },

    label: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validatePhone = (rule, value, callback) => {
      if (this.isValid && value.trim().length) {
        callback();
      } else {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.phone_number') })));
      }
    };

    return {
      form: {
        source: '',
      },

      isValid: false,

      rules: {
        source: [
          {
            validator: validatePhone,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  created() {
    this.form.source = this.source;
  },

  methods: {
    handlePhoneValidate(n, validator) {
      // console.log('validator', validator);
      // console.log('source', this.form.source);
      this.isValid = validator && validator.valid;

      this.$refs.form.validateField('source', (err) => {
        if (!err) {
          // Commented out to support local numbers as Source
          // this.form.source = validator.number.international;

          this.$emit('update-source', this.form.source, 'source', { ...validator.country });
        } else {
          this.$emit('update-source', '', 'source');
        }
      });
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
  .voice-source {
    .vue-tel-input {
      border: 1px solid #DCDFE6;
      height: 34px;

      .vti__dropdown {
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 7px;
        padding-right: 7px;
        outline: 0;

        input {
          font-size: 0.75rem;
        }

        .vti__dropdown-list {
          border: 1px solid #DCDFE6;
        }
      }
    }
  }
</style>
