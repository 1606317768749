<!-- eslint-disable max-len -->
<template>
  <div class="summary">
    <div
      v-if="form.Recipients.source.match(/UPLOAD/i)"
      class="border border-grey-light bg-grey-lighter p-3 rounded-sm flex justify-between
      text-xs w-full items-center"
    >
      <div>
        <p class="text-grey-dark">{{ $t('mcs.recip.summary[0]') }}</p>
        <p class="text-black mt-2">
          {{ form.Recipients.FileName }}
          <strong v-if="form.Recipients.FileSize">
            ({{ form.Recipients.FileSize }})
          </strong>
        </p>
      </div>
      <div>
        <el-button
          class="mr-2"
          size="small"
          @click.prevent="discardUpload"
        >
          {{ $t('mcs.recip.summary[1]') }}
        </el-button>
      </div>
    </div>
    <div
      v-else
      class="border border-grey-light bg-grey-lighter p-3 rounded-sm flex justify-between
      text-xs w-full items-center"
    >
      <div>
        <p class="text-grey-dark">{{ $t('mcs.recip.summary[2]') }}</p>
        <p class="text-black mt-2 ellipsis">
          {{ getContactAndGroups().toString().replace(/,/g, ', ') }}
        </p>
      </div>
      <div>
        <el-button
          size="small"
          class="mr-2"
          @click.prevent="discardUpload"
        >
          {{ $t('mcs.recip.summary[1]') }}
        </el-button>
      </div>
    </div>

    <div>
      <div class="my-5">
        <div v-if="form.Recipients.source.match(/UPLOAD/i)">
          <h3 class="mb-2 text-sm text-black tracking-normal">{{ $t('mcs.recip.summary[3]') }}</h3>
          <p class="text-xs text-grey-dark leading-tight">
            {{ $t('mcs.recip.summary[4]') }}
          </p>
          <div v-if="customFields.length" class="flex items-center my-2">
            <TagList
              v-model="customFields"
              :min="1"
              :disableDelete="['MSISDN']"
              @change="updateCustomFields"
              class="mr-3"
            />
            <p class="text-xs text-grey-dark mt-1">
            <a
              @click.prevent="$emit('change-step', 'SET_CUSTOM_FIELD')"
              class="text-blue hover:text-blue-light cursor-pointer">
               {{ $t('mcs.recip.summary[5]') }}
            </a>
            </p>
          </div>
        </div>
        <h3 class="mt-5 mb-2 text-sm text-black tracking-normal"> {{ $t('mcs.recip.summary[6]') }}</h3>
        <div class="flex relative items-stretch -mx-1">
          <div class="w-full flex flex-wrap px-1 -m-1">
            <SummaryCard class="w-1/2 p-1">
              <template slot="body">
                <div>
                  <h4 class="text-sm text-black font-normal">{{ $t('column_labels.total') }}</h4>
                  <span
                    v-if="form.Recipients.source.match(/UPLOAD/i)"
                    class="text-2xl text-black my-3 block">
                    {{ formatNumber(form.Recipients.Summary.Total) }}
                  </span>
                  <span v-else class="text-2xl text-black my-3 block">
                    {{ formatNumber(getTotalNumbers()) }}
                  </span>
                  <p class="text-xs text-grey-dark leading-tight">
                     {{ $t('mcs.recip.summary[7]') }}
                  </p>
                </div>
              </template>
            </SummaryCard>

            <SummaryCard class="w-1/2 p-1">
              <template slot="body">
                <div class="flex justify-between">
                  <div>
                    <h4
                      class="text-sm text-black font-normal"
                      :class="[{ 'text-red': !isValid() }]"
                    >
                      {{ $t('column_labels.valid') }}
                    </h4>
                    <span
                      v-if="form.Recipients.source.match(/UPLOAD/i)"
                      class="text-2xl text-black my-3 block"
                      :class="[{ 'text-red': !isValid() }]"
                    >
                      {{ formatNumber(form.Recipients.Summary.ValidEntries) }}
                    </span>
                    <span v-else class="text-2xl text-black my-3 block">
                      {{ formatNumber(summaryData.result.ContactsUniqueCount || 0) }}
                    </span>
                    <p class="text-xs text-grey-dark leading-tight">
                       {{ $t('mcs.recip.summary[8]') }}
                    </p>
                  </div>
                  <i class="el-icon-success text-4xl text-teal-light"></i>
                </div>
              </template>
            </SummaryCard>

            <SummaryCard class="w-1/2 p-1">
              <template slot="body">
                <div class="flex justify-between">
                  <div>
                    <h4 class="text-sm text-black font-normal"> {{ $t('mcs.recip.summary[9]') }}</h4>
                    <span
                      v-if="form.Recipients.source.match(/UPLOAD/i)"
                      class="text-2xl text-black my-3 block">
                      {{ formatNumber(form.Recipients.Summary.InvalidEntries) }}
                    </span>
                    <span v-else class="text-2xl text-black my-3 block">
                      {{
                        formatNumber(
                          (summaryData.result.ContactsUniqueCount || 0) - getTotalNumbers()
                        )
                      }}
                    </span>
                    <p class="text-xs text-grey-dark leading-tight">
                       {{ $t('mcs.recip.summary[10]') }}
                    </p>
                  </div>
                  <i class="el-icon-error text-4xl text-red-light"></i>
                </div>
              </template>
              <template slot="footer">
                <a
                  class="my-1 inline-block"
                  :class="[
                    (hasMalformed()
                      ? 'text-blue hover:text-blue-light cursor-pointer'
                      : 'text-grey-dark cursor-not-allowed')
                  ]"
                  @click="downloadInvalidFiles()">
                   {{ $t('mcs.recip.summary[11]') }}
                </a>
              </template>
            </SummaryCard>

            <SummaryCard class="w-1/2 p-1">
              <template slot="body">
                <div class="flex justify-between">
                  <div>
                    <h4 class="text-sm text-black font-normal">{{ this.$t('column_labels.duplicates') }}</h4>
                    <span
                      v-if="form.Recipients.source.match(/UPLOAD/i)"
                      class="text-2xl text-black my-3 block">
                      {{ formatNumber(form.Recipients.Summary.DuplicateEntries) }}
                    </span>
                    <span v-else class="text-2xl text-black my-3 block">
                      {{ formatNumber(summaryData.result.ContactsDuplicatesCount || 0) }}
                    </span>
                    <transition name="fade-up" mode="out-in">
                      <div :key="form.Recipients.AllowDuplicates">
                        <p v-if="form.Type === 'VOICE'" class="text-xs text-grey-dark leading-tight">
                           {{ $t('mcs.recip.summary[12]') }}
                        </p>
                        <p v-else class="text-xs text-grey-dark leading-tight">
                           {{ $t('mcs.recip.summary[13]') }}
                        </p>
                      </div>
                    </transition>
                  </div>
                  <i class="el-icon-warning text-4xl text-indigo-light"></i>
                </div>
              </template>
              <template slot="footer">
                <el-switch
                  :disabled="!hasDuplicates() || form.Type === 'VOICE'"
                  :value="form.Recipients.AllowDuplicates"
                  @change="v => updateRecipients({ AllowDuplicates: v })"
                  active-color="#14CD65"
                  :active-text="$t('mcs.recip.summary[19]')">
                </el-switch>
              </template>
            </SummaryCard>
          </div>
          <div class="w-full px-1">
            <SummaryCard class="h-full w-full">
              <template slot="body">
                <h4 class="text-lg text-black font-normal">{{ $tc('column_labels.destinations', 2) }}</h4>
                <div class="h-full flex justify-center items-center">
                  <DonutChart
                    :title="$t('messaging.dashboard.index[3]')"
                    :data="chartData"
                  >
                    <template slot="hover-icon">
                      <i class="el-icon-message mb-1 text-lg text-grey-dark"></i>
                    </template>
                  </DonutChart>
                </div>
              </template>
              <template slot="footer">
                <p v-if="!destinationsData.length" class="text-grey-dark my-1 inline-block">
                   {{ $t('mcs.recip.summary[14]') }}
                </p>
                <ul v-else class="list-reset my-1 inline-flex">
                  <li
                    v-for="(data, i) in destinationsData.slice(0, 3)"
                    :key="i"
                    class="mx-2 flex items-center"
                  >
                    <svg height="10" width="10">
                      <circle cx="5" cy="5" r="4" :fill="getChartColorByIndex(i)" />
                    </svg>
                    <span class="ml-1 text-grey-dark">
                      {{ data.country }}
                      ({{ formatNumber(data.total) }})
                    </span>
                  </li>
                  <li v-if="destinationsData.length >= 4" class="mx-2 flex items-center">
                    <el-tooltip
                      class="item cursor-pointer"
                      effect="dark"
                      :content="getExcessDestinationData().map(d => `${d.country}
                      (${formatNumber(d.total)}) `)"
                      placement="bottom"
                    >
                      <div slot="content">
                        <ul class="list-reset">
                          <li
                            v-for="(data, i) in destinationsData.slice(3)"
                            :key="i"
                            class="mx-2 flex items-center"
                          >
                            <svg height="10" width="10">
                              <circle cx="5" cy="5" r="4" :fill="getChartColorByIndex(i+3)" />
                            </svg>
                            <span class="ml-1 text-white">
                              {{ data.country }}
                              ({{ formatNumber(data.total) }})
                            </span>
                          </li>
                        </ul>
                      </div>
                      <span class="ml-1 text-grey-dark hover:text-blue">
                        <!-- and +{{ getExcessDestinationData().length }} more -->
                        {{ $t('mcs.recip.summary[15]', { excess: getExcessDestinationData().length }) }}
                      </span>
                    </el-tooltip>
                  </li>
                </ul>
              </template>
            </SummaryCard>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <div v-if="!canSendCampaigns" class="text-red-light mb-2 text-sm">
          {{ $t('mcs.subscription_warning') }}
        </div>
      <el-button
        @click="$emit('discard')"
      >
        {{ this.$t('actions.cancel') }}
      </el-button>
      <el-button
        :disabled="!isValid()"
        :type="isValid() ? 'primary' : 'info'"
        @click="$emit('done-recipients')"
        id="mcs-add-recipients"
      >
        {{ isValid() ? $t('mcs.recip.summary[16]') : $t('mcs.recip.summary[17]') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';
import { mapGetters, mapActions } from 'vuex';
import countriesJson from '@/json/countries.json';
import TagList from '@/components/TagList.vue';
import SummaryCard from '@/components/SummaryCard.vue';
import DonutChart from '@/components/DonutChart.vue';

export default {
  name: 'add-recipients',

  components: {
    TagList,
    SummaryCard,
    DonutChart,
  },

  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters({
      summary: 'recipientsContacts/getSummary',
      user: 'user/getUser',
      canSendCampaigns: 'user/getCanSendCampaigns',
    }),

    chartData() {
      const list = this.destinationsData;
      const desinationData = list.sort((a, b) => b.total - a.total);
      const formatChartData = desinationData.map((d, i) => ({
        label: d.country,
        value: d.total,
        color: this.getChartColorByIndex(i),
      }));
      return formatChartData;
    },
  },

  data() {
    return {
      summaryData: {},
      countriesJson,
      customFields: [],
      includeDuplicates: false,
      chartColor: ['#9F87FF', '#515E79', '#4BCFC5', '#FF6C6B', '#3F9EFF'],
      destinationsData: [],
    };
  },

  created() {
    this.populateCustomFieldsTag();

    // change summaryData value depending on type (UPLOAD/INPUT)
    if (this.form.Recipients.source.match(/UPLOAD/i)) {
      const recipientsSummary = this.form.Recipients.Summary;
      const countries = Object.keys(recipientsSummary.Countries).map(
        c => ({
          Country: c,
          ContactsCount: recipientsSummary.Countries[c],
        }),
      );
      const result = {
        ContactsUniqueCount: recipientsSummary.Total - recipientsSummary.Total.DuplicateEntries,
        ContactsDuplicatesCount: recipientsSummary.DuplicateEntries,
      };
      this.summaryData = { result, countries };
    } else {
      this.summaryData = this.summary;
    }

    this.destinationsData = this.summaryData.countries.map((c) => {
      let countryName = 'Unknown';
      if (c.Country !== null && c.Country !== 'null' && c.Country.trim() !== '') {
        countryName = this.countriesJson.find(d => d.value === c.Country).name || c.countryName;
      }

      return {
        country: countryName,
        total: c.ContactsCount,
      };
    });
  },

  methods: {
    ...mapActions({
      downloadFile: 'common/downloadFile',
      recipientsInvalid: 'recipientsContacts/downloadInvalid',
    }),

    hasDuplicates() {
      return this.form.Recipients.source.match(/UPLOAD/i)
        ? (this.form.Recipients.Summary.DuplicateEntries > 0)
        : (this.summaryData.result.ContactsDuplicatesCount > 0);
    },

    hasMalformed() {
      return this.form.Recipients.source.match(/UPLOAD/i)
        ? (this.form.Recipients.Summary.InvalidEntries > 0)
        : ((this.summaryData.result.ContactsUniqueCount || 0) - this.getTotalNumbers() > 0);
    },

    async downloadInvalidFiles() {
      if (!this.form.Recipients.Summary.InvalidEntries) { return 0; }

      const { FileName, FileUrl } = this.form.Recipients;
      const params = {
        fileName: FileName,
        location: FileUrl,
      };

      const { downloadURL } = await this.recipientsInvalid(params);

      return this.downloadFile(downloadURL);
    },

    isValid() {
      const validEntries = this.form.Recipients.Summary && this.form.Recipients.Summary.ValidEntries ? this.form.Recipients.Summary.ValidEntries : 0;
      const entriesCount = this.form.Recipients.source.match(/INPUT/i)
        ? this.form.Recipients.Summary.result.ContactsUniqueCount
        : validEntries;
      return entriesCount >= 1;
    },

    getTotalNumbers() {
      return this.summaryData.countries.reduce((acc, curr) => acc + curr.ContactsCount, 0);
    },

    // Switch allow duplicates in state
    updateRecipients(obj) {
      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          ...obj,
        },
      });
    },

    formatPhoneNumber(n, type = 'international') {
      const s = n.match(/^[+][0-9]/) ? n : `+${n}`;
      const pn = parsePhoneNumber(s);
      return pn.number[type] || n;
    },

    getContactAndGroups() {
      const { contacts, groups } = this.form.Recipients;
      const formattedContacts = contacts.map(c => this.formatPhoneNumber(c));
      const groupNames = groups.map(g => g.name);
      return [...formattedContacts, ...groupNames];
    },
    // Populate column label tags
    populateCustomFieldsTag() {
      if (this.form.Recipients.source.match(/UPLOAD/i)
          && Object.keys(this.form.Recipients.columnLabels).length) {
        const { columnLabels } = this.form.Recipients;
        this.customFields = Object.keys(columnLabels).filter(col => columnLabels[col] >= 0);
      }
    },
    updateCustomFields(v) {
      const { columnLabels } = this.form.Recipients;
      const newColumnLabels = { ...columnLabels, [v]: -1 };

      this.$emit('update-form', {
        Recipients: {
          ...this.form.Recipients,
          columnLabels: { ...newColumnLabels },
        },
      });
    },
    formatNumber(n) {
      return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    getExcessDestinationData() {
      return this.destinationsData.slice(3);
    },
    getChartColorByIndex(index) {
      return this.chartColor[index % this.chartColor.length];
    },
    discardUpload() {
      this.$confirm(this.$t('mcs.recip.summary[18]'), this.$t('actions.edit'), {
        confirmButtonText: this.$t('actions.discard'),
        cancelButtonText: this.$t('actions.cancel'),
        type: 'warning',
      }).then(() => {
        this.$emit('reset-upload');
        this.$emit('reset-contacts');
        this.$emit('change-step', 'ADD_RECIPIENT');
      });
    },
  },
};
</script>
