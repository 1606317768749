
export default {
  computed: {
    variables() {
      // let assigned = ['FirstName', 'LastName'];
      let assigned = [];

      if (this.form.Recipients && this.form.Recipients.source && this.form.Recipients.source === 'UPLOAD') {
        assigned = Object.keys(this.form.Recipients.columnLabels).reduce((a, b) => {
          if (this.form.Recipients.columnLabels[b] !== -1) {
            const k = {};
            k.variable = b.toLowerCase() === 'msisdn' ? 'Mobile' : b;
            k.pos = parseInt(this.form.Recipients.columnLabels[b], 10);
            a.push(k);
          }

          return a;
        }, []);

        assigned = assigned.sort((a, b) => ((a.pos > b.pos) ? 1 : -1));

        assigned = assigned.map(v => v.variable);
      }

      return assigned;
    },

    channels() {
      let ch = [];

      if (this.form.FallbackConfig) {
        const c = this.form.FallbackConfig.slice(0);

        if (c.length) {
          ch = c.map(v => v.ChannelType);
        }
      }

      return ch;
    },
  },

  methods: {
    hasWhatsApp() {
      return this.form.FallbackConfig
        && this.form.FallbackConfig.length
        && this.form.FallbackConfig.some(v => v.ChannelType === 'WA');
    },

    isWhatsAppSetAsMain() {
      return this.form.FallbackConfig
        && this.form.FallbackConfig.length
          && this.form.FallbackConfig[0].ChannelType === 'WA';
    },

    getMainChannel() {
      if (this.form.FallbackConfig && this.form.FallbackConfig.length) {
        return this.form.FallbackConfig[0].ChannelType;
      }

      return '';
    },

    channelDoesSupportText() {
      return this.getMainChannel().toUpperCase().match(/LN|VB/i);
    },

    channelDoesSupportImage() {
      return this.getMainChannel().toUpperCase().match(/LN|VB/i);
    },

    channelDoesSupportRichMedia() {
      return this.getMainChannel().toUpperCase().match(/VB/i);
    },

    hasMessageTypes() {
      return this.getMainChannel().toUpperCase().match(/LN|VB/i);
    },

    hasSmsChannel() {
      return this.form.FallbackConfig
        && this.form.FallbackConfig.length
        && this.form.FallbackConfig.some(v => v.ChannelType.toLowerCase() === 'sm');
    },

    getCountriesWithContactTotals() {
      const { source, Summary: summary } = this.form.Recipients;

      let countries = [];

      if (source === 'INPUT') {
        countries = summary.countries.reduce((a, b) => {
          if (b.Country) {
            // eslint-disable-next-line no-param-reassign
            a[b.Country] = b.ContactsCount;
          }
          return a;
        }, { });
      }

      if (source === 'UPLOAD') {
        countries = summary.Countries;
      }

      return countries;
    },

    estimatedSMSCostContract(priceRange) {
      const d = this.$vars.PRICE_DECIMAL;
      const l = this.$vars.LOCALE;
      const {
        totalContractMin,
        totalContractMax,
        contractCurrency,
      } = priceRange;
      const min = this.$wcsender.formatPrice(totalContractMin, d, l);
      const max = this.$wcsender.formatPrice(totalContractMax, d, l);

      if (totalContractMin === totalContractMax) {
        return `${contractCurrency} ${min}`;
      }

      return `${contractCurrency} ${min} - ${contractCurrency} ${max}`;
    },

    estimatedSmsCost(priceRange) {
      const d = this.$vars.PRICE_DECIMAL;
      const l = this.$vars.LOCALE;
      const {
        totalMin,
        totalMax,
        currency,
      } = priceRange;
      const min = this.$wcsender.formatPrice(totalMin, d, l);
      const max = this.$wcsender.formatPrice(totalMax, d, l);

      if (totalMin === totalMax) {
        return `${currency} ${min}`;
      }

      return `${currency} ${min} - ${currency} ${max}`;
    },

    estimatedChatAppsCost(priceRange) {
      const d = this.$vars.PRICE_DECIMAL;
      const l = this.$vars.LOCALE;

      const {
        total,
        currency,
      } = priceRange;

      const t = this.$wcsender.formatPrice(total, d, l);

      if (total === 0) {
        return '-';
      }

      return `${currency} ${t}`;
    },

    estimatedTime(payload = {}) {
      const { msgTotal, msgPerSec, estimatedTimeSec } = payload;

      let estTime = 0;

      if (estimatedTimeSec) {
        estTime = Math.round(parseInt(estimatedTimeSec, 10)); // seconds
      } else if (!estimatedTimeSec) {
        const noOfMsg = parseInt(msgTotal, 10);
        const perSec = parseInt(msgPerSec, 10);

        estTime = Math.round(noOfMsg / perSec); // seconds
      }

      // convert secs to mins
      const secsToMins = time => Math.ceil(time / 60);

      // convert mins to estimated mins depending on step
      // 23 mins = 25 mins, 19 mins = 20 mins
      const estMins = (min, step = 5) => step * Math.ceil(min / step);

      // convert to h,m,s
      const secsToTime = (secs) => {
        const seconds = Math.round(secs);
        const h = Math.floor(seconds / (60 * 60));

        const divisorForMins = seconds % (60 * 60);
        const m = Math.floor(divisorForMins / 60);

        const divisorForSecs = divisorForMins % 60;
        const s = Math.ceil(divisorForSecs);

        return { h, m, s };
      };

      let display = '';

      if (estTime <= 60) {
        display = this.$t('time_labels.less_than_a_minute');
      } else if (estTime > 60 && estTime <= 600) {
        const n = secsToMins(estTime);

        display = this.$t('time_labels.less_than_minutes', { n });
      } else if (estTime > 600 && estTime <= 3300) {
        const eTime = secsToMins(estTime);
        const n = estMins(eTime);

        display = this.$t('time_labels.less_than_minutes', { n });
      } else if (estTime >= 3300) {
        let hourDisplay = '';
        let minDisplay = '';

        const { h, m } = secsToTime(estTime);

        let hour = h;
        const min = estMins(m);

        if (estTime >= 3600 && m > 0) {
          minDisplay = `${min} ${this.$tc('time_labels.minute', 2)}`;
        }

        if (hour === 0 || (hour === 1 && min < 1)) {
          hour = 1;
          hourDisplay = `1 ${this.$tc('time_labels.hour', 1)}`;
          minDisplay = '';
        } else {
          if (min && min > 59) {
            hour = hour + 1; // eslint-disable-line
            minDisplay = '';
          }

          if (hour === 1) {
            hourDisplay = this.$t('time_labels.less_than_a_hour', { n: hour });
          } else {
            hourDisplay = this.$t('time_labels.less_than_hours', { n: hour });
          }
        }

        display = `${hourDisplay} ${minDisplay}`;
      }

      return display;
    },

    getEstimatedCostDisplay() {
      const { PriceRange: priceRange, Type: type } = this.form;

      if (type.match(/SMS/)) {
        if (priceRange.contractCurrency && priceRange.contractCurrency !== 'EUR') {
          return this.estimatedSMSCostContract(priceRange);
        }
        return this.estimatedSmsCost(priceRange);
      }

      if (type === 'CHAT_APPS') {
        return this.estimatedChatAppsCost(priceRange);
      }

      // todo: change this once there is calculated cost for voice
      // and probably create another func for voice
      if (type === 'VOICE') {
        return this.estimatedChatAppsCost(priceRange);
      }

      return '';
    },

    getEstimatedTimeDisplay() {
      const { Message: message, Type: type } = this.form;
      // only estimate valid Recipients
      const totalValidCount = this.getTotals().totalValidRecipients;

      if (type.match(/SMS/)) {
        const { smsSegments } = message;
        const msgPerSec = this.$vars.SMS_MSG_PER_SEC;
        const msgTotal = smsSegments.min * totalValidCount;

        return this.estimatedTime({ msgTotal, msgPerSec });
      }

      if (type === 'CHAT_APPS') {
        const msgPerSec = this.$vars.CHAT_APP_MSG_PER_SEC;

        return this.estimatedTime({ msgTotal: totalValidCount, msgPerSec });
      }

      if (type === 'VOICE') {
        const letterPerMs = this.$vars.VOICE_LETTER_PER_MS;
        const { message: m } = message;
        const msg = m.replace(/\s+/g, '');

        const estTime = (msg.length * letterPerMs / 1000) * totalValidCount;

        return this.estimatedTime({ estimatedTimeSec: estTime });
      }

      return '';
    },

    getTotals() {
      const { Recipients: rp, Type: type, Message: message } = this.form;
      const { Summary: summary } = rp;

      let total = 0;
      let validCount = 0;

      if (rp.source === 'INPUT') {
        const { ContactsUniqueCount: count } = summary.result;

        total = parseInt(count, 10);
        validCount = parseInt(total, 10);
      }

      if (rp.source === 'UPLOAD') {
        const {
          ValidEntries: valid,
          InvalidEntries: invalid,
          DuplicateEntries: duplicates,
        } = summary;

        total = parseInt(valid, 10) + parseInt(invalid, 10);

        validCount = parseInt(valid, 10);

        if (summary.allowDuplicates) {
          total += parseInt(duplicates, 10);
          validCount += parseInt(duplicates, 10);
        }
      }

      const validPercentage = validCount / total * 100;

      return {
        totalValidRecipients: validCount,
        totalSmsMsgs: type.match(/SMS/) ? (message.smsSegments.min * validCount) : 0,
        totalCaMsgs: validCount,
        totalVoiceMsgs: validCount,
        totalAllMsgs: type.match(/SMS/) ? (message.smsSegments.min * total) : total,
        validPercentage,
      };
    },
  },
};
