<template>
  <el-form
    @submit.prevent.native
    class="inline-block"
  >
    <div>
      <div
       v-if="!hasContactAccess()"
      class="mb-3 text-black text-xs p-3 bg-yellow-lightest
      border border-solid border-yellow rounded mb-5 w-3/5 leading-normal">
        <p class="leading-normal">
          {{ $t('mcs.recip.input[0]')}}
        </p>
      </div>
      <p class="mt-2 mb-2 text-sm text-grey-dark leading-none">
        {{ $t('mcs.recip.input[9]') }}
      </p>
      <div class="flex mobile-number">
        <el-select
          v-model="selectedCallingCode"
          filterable
          placeholder="Country"
          value-key="value"
          class="mobile-number-prefix"
          :class="[{ 'center-caret' : selectedCallingCode }]"
          @focus="onFocusSelectedCallingCode"
          no-match-text="No Data"
          @change="onChangeSelectedCallingCode"
        >
          <el-option
            v-for="(country, i) in countries"
            :key="i"
            :value="`${country.value}-${country.name}_${country.callingCode}`"
          >
            <span
              class="mr-2 border-grey-light flag-icon"
              :class="[`flag-icon-${country.value.toLowerCase()}`]"
            />
              {{ country.name }} {{ country.callingCode }}
          </el-option>
          <template v-if="selectedCallingCode" #prefix>
            <div class="flex selected-country-prefix">
              <span
                class="mr-2 border-grey-light flag-icon"
                :class="[`flag-icon-${getCountryCode(selectedCallingCode)}`]"/>
              {{ getCallingCode(selectedCallingCode) }}
            </div>
          </template>
        </el-select>

        <el-select
          v-model="contactSearch"
          :disabled="screenLoading || isTrialAccount()"
          :loading="loading"
          filterable
          remote
          reserve-keyword
          allow-create
          placeholder=""
          class="w-full mobile-number-input"
          @change="addContact"
        >
          <el-option-group
            v-for="(group, i) in mergedSelectOptions"
            :key="i"
            :label="group.label"
          >
            <template v-if="group.label && group.label === 'Contacts'">
              <el-option
                v-for="(contact, i) in group.options"
                :key="i"
                :label="contact.MSISDN"
                :value="contact.MSISDN"
                :disabled="isContactExist(contact)"
                >
                <span class="float-left text-sm">
                  <span
                    v-if="!isContactExist(contact) && getRegionCode(`+${contact.MSISDN}`)"
                    :class="[
                      `mr-2 border-grey-light
                      flag-icon flag-icon-${getRegionCode(`+${contact.MSISDN}`)}`
                    ]"/>
                  <i v-else class="el-icon-check text-green mr-2"></i>
                  {{ formatPhoneNumber(`+${contact.MSISDN || ''}`) }}
                </span>
                <span class="float-right text-xs text-grey">
                  {{ formatName(contact) }}
                </span>
              </el-option>
            </template>
            <template v-else-if="group.label && group.label === 'Contact groups'">
              <el-option
                v-for="(cg, i) in group.options"
                :disabled="isGroupExist(cg) || !cg.ContactsCount"
                :key="i"
                :label="cg.name || $t('mcs.recip.input[3]')"
                :value="cg.id"
                >
                <div class="float-left text-sm truncate w-2/3">
                  <i v-if="!isGroupExist(cg)" class="el-icon-user mr-2"></i>
                  <i v-else class="el-icon-check text-green mr-2"></i>
                  {{ cg.name }}
                </div>
                <div class="float-right text-xs text-grey">
                  {{
                    (cg.contacts > 1
                    ? `${Number(cg.contacts).toLocaleString()} contacts`
                    : `${cg.contacts} contact`)
                  }}
                </div>
                <div class="clearfix" />
              </el-option>
            </template>
          </el-option-group>
        </el-select>
      </div>
      <p class="mt-2 text-xs text-grey-dark leading-none" @dblclick="addToContacts(contactPlaceholder)">
        {{ $t('mcs.recip.input[8]', { contact: contactPlaceholder }) }}
      </p>
      <div v-if="contacts.length || groups.length" class="mt-4">
        <div class="flex justify-between items-center py-3">
          <h3 class="text-sm text-black tracking-normal">
            {{ $t('mcs.recipients') }}
          </h3>
          <p class="text-xs text-grey-dark leading-none">
            <!-- {{ contacts.length + groups.length }} selected -->
            {{ $t('mcs.recip.input[4]', { length: contacts.length + groups.length }) }}
          </p>
        </div>
        <ul
          v-loading="screenLoading"
          class="list-reset w-full border-t border-grey-light"
        >
          <!-- Contact list -->
          <li
            v-for="(contact, i) in contacts"
            :key="i"
            class="border-b border-grey-light py-3 px-2 text-xs text-black flex justify-between
            hover:bg-blue-lightest hover:text-blue"
          >
            <div class="flex items-center">
                <span
                  v-if="getRegionCode(`+${contact}`)"
                  class="mr-2 border-grey-light flag-icon text-xs"
                  :class="[
                    `flag-icon-${getRegionCode(`+${contact}`)}`
                  ]"/>
                <span>{{ formatPhoneNumber(contact) }}</span>
            </div>
            <el-tooltip
              v-if="!isTrialAccount()"
              effect="dark"
              :content="$t('actions.remove')"
              placement="right"
              :enterable="false"
              :open-delay="1000"
            >
              <button
                @click.prevent="removeContact(contact)"
                class="text-grey hover:text-red"
              >
                <i class="el-icon-delete"></i>
              </button>
            </el-tooltip>
          </li>
          <!-- Group list -->
          <li
            v-for="(group, i) in groups"
            :key="i"
            class="border-b border-grey-light py-3 px-2 text-xs text-black flex justify-between
            hover:bg-blue-lightest hover:text-blue"
          >
            <div class="flex items-center">
              <i class="el-icon-user mr-2 text-sm"></i>
              <span>{{ group.name }}</span>
            </div>
            <el-tooltip
              effect="dark"
              :content="$t('actions.remove')"
              placement="right"
              :enterable="false"
              :open-delay="1000"
            >
              <button
                @click.prevent="removeGroup(group)"
                class="text-grey hover:text-red"
              >
                <i class="el-icon-delete"></i>
              </button>
            </el-tooltip>
          </li>
        </ul>
      </div>
    </div>
    <el-form-item class="mt-10">
      <el-button
        :disabled="screenLoading"
        @click="$emit('discard')"
      >
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        ref="submitbutton"
        type="primary"
        :loading="loading || screenLoading"
        @click="submit"
        id="mcs-process-contact-group"
      >
      {{ loading || screenLoading ?  $t('wait.processing') :  $t('mcs.recip.set_fields[10]') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getExample, parsePhoneNumber } from 'awesome-phonenumber';
import _ from 'lodash';
import { mapActions } from 'vuex';
import countriesJson from '@/json/countries.json';

export default {
  name: 'input-recipients',

  data() {
    return {
      // loading: false,
      screenLoading: false,
      contactSearch: '',
      contactOptions: [],
      mergedSelectOptions: [],
      countries: [],
      selectedCallingCode: '',
      contactPlaceholder: '',
    };
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    contacts: {
      type: Array,
      default: () => {},
    },

    groups: {
      type: Array,
      default: () => {},
    },

    groupOptions: {
      type: Object,
      default: () => ({}),
    },

    searchContactByPhone: {
      type: Function,
      default: () => {},
    },

    userData: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    if (this.isTrialAccount() && this.getUserPhoneNo() && this.userData.PhoneVerified) {
      this.validateContact(this.getUserPhoneNo());
    } else {
      this.remoteMethod();
    }
    this.countries = countriesJson;
    const uc = localStorage.getItem('user_country');

    const userCountry = this.userData.CountryCode || uc.split(';')[1];
    const callingCode = this.countries.find(c => c.value === userCountry);
    this.selectedCallingCode = `${callingCode.value}-${callingCode.name}_${callingCode.callingCode}`;
    this.contactPlaceholder = getExample(callingCode.value, 'mobile').number.international;
  },

  methods: {
    ...mapActions({
      getSummary: 'recipientsContacts/getRecipientsSummary',
    }),

    onFocusSelectedCallingCode() {
      this.selectedCallingCode = '';
    },

    onChangeSelectedCallingCode(val) {
      const selectedCountry = this.getCountryCode(val);
      this.contactPlaceholder = getExample(selectedCountry, 'mobile').number.international;
    },

    getCountryCode(val) {
      const code = val.substring(0, val.indexOf('-'));

      return code.toLowerCase();
    },

    getCallingCode(val) {
      return val.split('_')[1];
    },

    async submit() {
      const { contacts, groups } = this;

      if (contacts.length || groups.length) {
        this.screenLoading = true;
        try {
          const data = await this.getSummary({ contacts, groups });
          this.$emit('submit-contacts', data);
        } catch (err) {
          this.$showError(this, err);
          // this.$message.error(err.message || 'Unable to get subaccounts');
        } finally {
          this.screenLoading = false;
        }
      } else {
        // this.$message.error(this.$t('mcs.recip.input[5]'));
        this.$notify.error({
          title: this.$t('mcs.recip.input[5]'),
        });
      }
    },

    addContact(s) {
      this.validateContact(s);
      this.contactSearch = '';
      this.$nextTick(function tick() {
        this.$refs.submitbutton.$el.focus();
      });
    },

    isContactExist(c) {
      const { msisdn } = c.addresses;
      return this.contacts.includes(msisdn);
    },

    removeGroup(group) {
      const groups = this.groups.filter(g => g.id !== group.id);
      this.groups = groups;

      this.$emit('update-form', {
        Recipients: {
          contacts: this.contacts,
          groups,
        },
      });
    },

    validateContact(contact) {
      const callingCode = this.getCallingCode(this.selectedCallingCode);
      const pn = parsePhoneNumber(`${callingCode}${contact}`);
      try {
        if (pn.valid) {
          const phonenumber = pn.countryCode + pn.number.significant;
          this.addToContacts(phonenumber);
        } else {
          const error = new Error('Destination numbers must be in an international format. (e.g. +65 9876 5432)');
          this.$showError(this, error, { hideContactUs: true });
        }
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error(err.message);
      }
    },

    addToContacts(phonenumber) {
      if (!this.contacts.includes(phonenumber)) {
        this.$emit('update-form', {
          Recipients: {
            contacts: [...this.contacts, phonenumber],
            groups: this.groups,
          },
        });
      }
    },

    removeContact(contact) {
      const contacts = this.contacts.filter(c => c !== contact);
      this.contacts = contacts;

      this.$emit('update-form', {
        Recipients: {
          contacts,
          groups: this.groups,
        },
      });
    },

    debounceQuery: _.debounce(function dbnce(s) { this.remoteMethod(s); }, 1000),

    async remoteMethod(query) { // eslint-disable-line
      /*
      const q = query ? query.trim() : query;
      this.mergedSelectOptions = [];
      const sortedGroups = [...this.groupOptions].sort((a, b) => b.GroupName.localeCompare(q
        || b.GroupName));
      // make sure user has `Contacts` module
      if (q && this.hasContactAccess()) {
        const filteredGroupOptions = (sortedGroups || [])
          .filter(
            g => g.GroupName.toLowerCase()
              .includes(query.toLowerCase()),
          );
        try {
          this.loading = true;
          const { contacts } = await this.searchContactByPhone(query);
          this.contactOptions = contacts;

          this.mergedSelectOptions = [];

          if (this.contactOptions.length) {
            this.mergedSelectOptions.push({ label: 'Contacts', options: this.contactOptions });
          }

          if (filteredGroupOptions.length) {
            this.mergedSelectOptions.push({
              label: 'Contact groups',
              options: filteredGroupOptions
            });
          }
        } catch (err) {
          this.$message.error(err.message || 'Failed to search contact');
          this.mergedSelectOptions = [
            { label: 'Contact groups', options: filteredGroupOptions },
          ];
        } finally {
          this.loading = false;
        }
      } else {
        this.mergedSelectOptions = [
          { label: 'Contact groups', options: sortedGroups },
        ];
      }
      */
    },

    formatPhoneNumber(n) {
      const s = n.match(/^[+][0-9]/) ? n : `+${n}`;
      const pn = parsePhoneNumber(s);
      return pn.number.international || n;
    },

    formatName(contact) {
      const { firstName, lastName } = contact;

      if (!firstName && !lastName) {
        return this.$t('mcs.recip.input[6]');
      }
      return `${firstName} ${lastName}`.trim();
    },

    getRegionCode(s) {
      const pn = parsePhoneNumber(s);
      return pn.regionCode ? pn.regionCode.toLowerCase() : null;
    },

    isTrialAccount() {
      return Boolean(this.userData.IsTrialAccount);
    },

    getUserPhoneNo() {
      return this.userData.Phone || '';
    },

    hasContactAccess() {
      return this.userData.Roles.includes('Contacts_v2');
    },
  },
};
</script>

<style lang="scss">
  .mobile-number {
    .selected-country-prefix {
      width: 100%;
      height: 91%;
      padding: 10px;
      background: #f1f1f1;
      margin-top: 2px;
      justify-content: space-between;
    }

    &-prefix {
      width: 170px;

      &.center-caret {
        .el-input__suffix {
          transition: all 0.3s ease;
          right: 50px;
        }
      }

      .el-input--suffix {
        .el-input__inner {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .el-input__prefix {
        width: 93%;
      }
    }

    &-input {
      .el-input--suffix {
        .el-input__inner {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
</style>
