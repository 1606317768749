<template>
  <ul class="channels-fallback list-reset flex flex-wrap -my-2">
    <li
      v-for="(channelCode, i) in channels"
      :key="i"
      class="inline-block text-xs flex items-center py-2"
    >
      <img
        :src="getChannelIcon(channelCode)"
        class="channels-fallback__icon mr-1"
      >
      <p class="leading-none" v-if="showLabel">
        {{ getChannelName(channelCode) }}
      </p>
      <i
        v-if="i < channels.length-1"
        class="el-icon-arrow-right mx-4 color-grey-lightest leading-none"
      ></i>
    </li>
  </ul>
</template>

<script>
import channelsJson from '@/json/channels.json';
import CampaignListMixin from '@/mixins/campaign-list';

export default {
  name: 'channelsFallback',

  mixins: [CampaignListMixin],

  props: {
    channels: {
      type: Array,
      default: () => [],
      required: true,
    },

    showLabel: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      channelsJson,
    };
  },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
.channels-fallback {
  &__icon {
    height: 20px;
  }
}
</style>
