import { parsePhoneNumber } from 'awesome-phonenumber';
import Moment from 'moment';
// eslint-disable-next-line import/prefer-default-export
export function fixBreakLines(text) {
  const br2nl = (str, replaceMode) => {
    const replaceStr = replaceMode ? '\n' : '';
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[/]?>/gi, replaceStr); // disable-eslint-line
  };

  let t = text.trim();

  t = br2nl(t, true);

  t = t.replace(/&nbsp;/g, '');

  return t;
}

export function transformToWAValidTemplate(content) {
  let c = content.trim();

  if (!c) return '';


  if (c.match(/<[^>]*>/g)) {
    c = c.replace(/<br\s*(\s+[^>]+)*[/]?>/gi, '<p></p>');
    c = c.replace(/<\/p>/g, '\n').replace(/<p>/g, '');

    // for bold element
    c = c.replace(/<strong>|<\/strong>/g, '*');
    c = c.replace(/<b>|<\/b>/g, '*');

    // for italic element
    c = c.replace(/<em>|<\/em>/g, '_');
    c = c.replace(/<i>|<\/i>/g, '_');

    // for strikethrough
    c = c.replace(/<s>|<\/s>/g, '~');
    c = c.replace(/<strike>|<\/strike>/g, '~');
    c = c.replace(/<del>|<\/del>/g, '~');
  }

  return c;
}

export function transformToHTML(content) {
  let c = content.trim();

  if (!c) return '';

  c = c.replace(/\*(\S[^*]+\S)\*/g, '<strong>$1</strong>');
  c = c.replace(/_(\S[^_]+\S)_/g, '<em>$1</em>');
  c = c.replace(/~(\S[^~]+\S)~/g, '<s>$1</s>');

  return c;
}

export function hasBrackets(content = '') {
  if (content) {
    return content.trim().match(/\{\{.*?\}\}/g);
  }

  return false;
}

export function removeBrackets(text = '') {
  if (text) {
    return text.trim().replace('{{', '').replace('}}', '');
  }

  return false;
}

export function hasUrlLink(content = '') {
  if (!content) return false;

  // eslint-disable-next-line no-useless-escape
  return content.match(/((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/g);
}

export function replaceLink(content = '') {
  if (!content) return '';

  let c = content;

  // eslint-disable-next-line no-useless-escape
  const matched = content.match(/((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*((:[0-9]+)|(\.[a-zA-Z]{2,3}\/?)))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]/g);

  if (matched && matched.length) {
    matched.forEach((v) => {
      const rep = `<a href="${v}" class="text-blue hover:text-blue-darker" target="_blank" rel="noreferrer" rel="nofollow">${v}</a>`;
      c = content.replace(v, rep);
    });

    return c;
  }

  return c;
}

export function urlResolver(url, imgUrl) {
  if (imgUrl.indexOf(url) !== -1) {
    return imgUrl;
  }

  return `${url}${imgUrl}`;
}

export function formatBytes(bytes) {
  if (bytes === 0) {
    return {
      size: '0',
      label: 'B',
    };
  }
  const k = 1024; // Or 1 kilo = 1000
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    // eslint-disable-next-line no-restricted-properties
    size: parseFloat((bytes / Math.pow(k, i))),
    label: sizes[i],
  };
}

export function factorPascalCasedWords(v) {
  let w = v;

  if (v) {
    const hasMatched = v.match(/(^[a-z]|[A-Z0-9])[a-z]*/g);

    if (hasMatched && hasMatched.length > 1) {
      w = hasMatched.map(x => x.toLowerCase()).join(' ');
    }
  }

  return w;
}

export function removeUnicodeChars(str) {
  if (!str) return '';

  const s = str.trim().replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');

  return s;
}

export function prettifyPhoneNumber(n) {
  const s = n.match(/^[+][0-9]/) ? n : `+${n}`;
  const pn = parsePhoneNumber(s);
  return pn.number.international || n;
}

export function isJSON(str) {
  if (typeof str === 'string') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
}

export function checkIfDayHasPassed(date) {
  const today = Moment();
  return today.diff(Moment(date), 'hours') >= 24;
}

export function getHumanReadable(seconds) {
  if (seconds === 1) return '1 second';

  Moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '%d seconds',
      m: '%d minute',
      h: '%d hour',
      d: '%d day',
      w: '%d week',
      M: '%d month',
      y: '%d year',
    },
  });
  return Moment.duration(seconds, 'seconds').humanize();
}
