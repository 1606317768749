<template>
  <div class="voice-preview">
    <h2 class="text-base text-black font-normal mb-3">
     {{ $t('actions.preview') }}
    </h2>
    <p class="text-xs leading-tight text-grey-darker mb-2">
      {{ $t('mcs.preview') }}
    </p>
    <!--flex content-between flex-wrap -->
    <div
      class="overflow-y-auto bg-blue-lightest rounded-lg border border-solid border-blue p-5
      prev-content h-full flex content-between flex-wrap"
    >
      <div class="w-full text-center mb-5">
        <p class="flex-none text-sm text-grey mb-2"> {{ $t('column_labels.source') }}</p>
        <p class="flex-none text-md text-grey-darker">{{ source || '-'}} </p>
      </div>

      <div
        v-if="message.trim()"
        class="relative bubble leading-normal min-w-full bg-white shadow px-2 pt-2 pb-5 text-xs
        rounded-tl-lg rounded-r-lg"
      >
        <div class="text-sm text-grey-darker font-medium">
          <i class="el-icon-phone-outline mr-1" /> <i class="el-icon-more-outline"/>
        </div>
        <div
          class="prev-wrapper whitespace-pre-wrap break-words max-h-full"
          v-html="getPreviewContent(message.trim(), variables)"
        />
      </div>

      <!-- <div class="w-full">
        <div class="rounded-full py-2 px-2 bg-white flex">
          <div
            :class="[
              'flex-shrink mr-5 rounded-full h-10 w-10 flex items-center justify-center',
              { 'bg-blue' : source && message },
              { 'bg-blue-lighter' : !source || !message }
            ]"
          >
            <i class="el-icon-caret-right text-4xl text-white" />
          </div>
          <div class="flex-auto py-3">
            <span
              :class="[
                'text-md',
                {'text-grey-light ': !source || !message },
                {'text-black': source && message }
              ]">
              Play the message
            </span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import previewContentMixin from '@/mixins/preview-content';

export default {
  name: 'message_voice_preview',

  mixins: [previewContentMixin],

  props: {
    source: {
      type: String,
      default: '',
    },

    message: {
      type: String,
      default: '',
    },

    variables: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
  },

};
</script>
<style lang="scss">
  .voice-preview {
    .prev-content {
      min-height: 330px;
      max-height: 900px;
      padding-bottom: 2rem;
    }

    // .bubble:before {
    //   content: ' ';
    // position: absolute;
    // width: 0;
    // height: 0;
    // left: 30px;
    // right: auto;
    // top: auto;
    // bottom: -40px;
    // border: 20px solid;
    // border-color: white transparent transparent white;
    // }
  }
</style>
