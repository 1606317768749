/* eslint-disable no-param-reassign */
import handlePackageLimit from '../../../utils/handlePackageLimit';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    user: {},
    subAccounts: [],
    userBalance: {},
    hasSmsAccess: false,
    hasChatAppsAccess: false,
    hasSmsEngageAccess: false,
    hasVoiceAccess: false,
    showChatMessageBody: false,
    showSmsMessageBody: false,
    hasPaymentAccess: false,
    canSendCampaigns: true,
    hasPricingEntitlement: true,
    timeZoneName: null,
    timeZoneOffset: 0,
    timeZone: '(UTC-00)',
    isTimeZoneAllowed: false,
  },

  getters: {
    getUser: st => st.user,
    getUserBalance: st => st.userBalance,
    getSubAccounts: st => st.subAccounts,
    getSmsAccess: st => st.hasSmsAccess,
    getChatAppsAccess: st => st.hasChatAppsAccess,
    getSmsEngageAccess: st => st.hasSmsEngageAccess,
    getVoiceAccess: st => st.hasVoiceAccess,
    getChatMessageBodyAccess: st => st.showChatMessageBody,
    getSmsMessageBodyAccess: st => st.showSmsMessageBody,
    getCanSendCampaigns: st => st.canSendCampaigns,
    getPricingEntitlementAccess: st => st.hasPricingEntitlement,
    getTz: (st) => {
      const {
        timeZone,
        timeZoneName,
        timeZoneOffset,
      } = st;

      return {
        timeZone,
        timeZoneName,
        timeZoneOffset,
      };
    },
  },

  mutations: {
    SET_SUBACCOUNTS: (st, payload) => { st.subAccounts = payload.data; },
    SET_USER(st, payload) {
      // eslint-disable-next-line no-param-reassign
      st.user = payload.user;

      const {
        Product_SMS: hasSMSAccess,
        Product_CA: hasCAAccess,
        Product_VO: hasVoiceAccess,
        // NewRoles: roles,
        SmsToSurveyEnabled: hasSurvey,
        HasSubAccountsWithSurveyForms: hasSurveyForms,
        BetaFeatures: betaFeatures,
        Roles: userRoles,

        TimeZoneName,
        TimeZoneGMTOffset,
        AccessLevel: accessLevel,
        IsPostPaid: isPostPaid,
        Subscription: subscription,
      } = payload.user;

      st.hasSmsAccess = hasSMSAccess;
      st.hasChatAppsAccess = hasCAAccess;
      st.hasVoiceAccess = hasVoiceAccess && betaFeatures.includes('VO-Messaging');
      st.showChatMessageBody = userRoles.includes('CA.ShowMessageBody_v2') || accessLevel === 'A';
      st.showSmsMessageBody = userRoles.includes('SMS.ShowMessageBody_v2') || accessLevel === 'A';
      st.hasSmsEngageAccess = hasSMSAccess
        && hasSurvey && hasSurveyForms;
      st.isTimeZoneAllowed = true;
      st.hasPaymentAccess = (userRoles.includes('Payment_v2') || accessLevel === 'A') && isPostPaid !== 1;

      if (subscription && subscription.Status) {
        st.canSendCampaigns = subscription.Package
          && subscription.Package.metadata
          && subscription.Package.metadata.accountSubscriptionFlag
          && subscription.Package.subscriptions.length;
        st.hasPricingEntitlement = handlePackageLimit('feature-cp-pricing');
      }

      // User timezone settings
      st.timeZoneName = TimeZoneName || 'UTC / Greenwich Mean Time'; // eslint-disable-next-line
      st.timeZoneOffset = TimeZoneGMTOffset ? (TimeZoneGMTOffset / 60) : 0; // offset should be in minutes
      let timeZone = '(UTC-00)';
      if (TimeZoneGMTOffset) {
        const offset = new Date(TimeZoneGMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
        let prefix = '-';
        if (TimeZoneGMTOffset > 0) {
          prefix = '+';
        }
        timeZone = `(UTC${prefix}${offset})`;
      }
      st.timeZone = timeZone;
    },

    SET_USER_BALANCE(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.userBalance = payload.balance;
    },
  },

  actions,
};
