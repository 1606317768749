<template>
  <div class="upload-recipients inline-block">
    <div>
      <h3 class="mb-2 text-sm text-black tracking-normal">
        {{ $t('actions.upload_file') }}
      </h3>
      <!-- <p class="text-xs text-grey-dark leading-tight">
        You must include a phone number column in your file.
        Other fields are optional, but <br /> can be used for personalization.
      </p> -->
      <i18n
        path="mcs.recip.upload[0]"
        tag="p"
        class="text-xs text-grey-dark leading-tight"
      >
        <template #br>
          <br>
        </template>
      </i18n>
      <div v-loading="processing || uploading">
        <el-upload
          id="upload-file-input"
          class="w-full mt-5"
          ref="upload"
          drag
          accept=" .csv, .txt, .xlsx"
          :show-file-list="false"
          :disabled="processing ||uploading "
          :action="
            `${apiUrl}/websender/upload?socketId=${socketId || ''}&queueUrl=${queueUrl || ''}`
          "
          :headers="getHeaders"
          :limit="1"
          :before-upload="handleBeforeUpload"
          :on-progress="handleProgress"
          :on-success="handleSuccess"
          :on-error="handleError">
          <i class="el-icon-upload text-sm"></i>
          <i18n
            path="upload_labels.drag_drop"
            tag="div"
            class="text-xs"
          >
            <template #br>
              <br>
            </template>
             <template #browse>
              <em class="text-blue roman">{{$t('actions.browse')}}</em>
            </template>
          </i18n>
          <div class="el-upload__tip flex justify-between" slot="tip">
            <div>
              <a
                class="text-blue hover:text-blue-dark cursor-pointer"
                @click.prevent="downloadSampleFile()"
              >
                {{$t('actions.download')}}
              </a>
              {{$t('mcs.recip.upload[1]')}}
            </div>
            <div>
              <strong>{{$t('upload_labels.supported_types')}}</strong>
              .csv/.txt/.xlsx ({{$t('upload_labels.size_less_than', { size: "30MB" })}})
            </div>
          </div>
          <i18n
        path="mcs.recip.upload[0]"
        tag="p"
        class="mt-5 mb-2 text-xs text-grey-dark leading-tight"
      >
        <template #br>
          <br>
        </template>
      </i18n>
        </el-upload>
      </div>
    </div>
    <div class="mt-10">
      <el-button
        id="mcs-processing-uploaded-contacts"
        :disabled="processing || uploading "
        @click="$emit('discard')"
      >
       {{ processing || uploading ? $t('wait.uploading') : $t('actions.cancel') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { ExportToCsv } from 'export-to-csv';

export default {
  name: 'upload-recipients',

  props: {
    form: {
      type: Object,
      default: () => {},
    },
    partialData: {
      type: Array,
      default: () => [],
    },
    processing: {
      type: Boolean,
      default: false,
    },
    socketId: {
      type: String,
      default: '',
    },
    queueUrl: {
      type: String,
      default: '',
    },
  },

  watch: {
    partialData() {
      this.$emit('change-step', 'SET_CUSTOM_FIELD');
    },
  },

  data() {
    return {
      uploading: false,
      apiUrl: `${process.env.VUE_APP_API_URL}/api/v1`,
    };
  },

  created() {
    this.$emit('set-processing', false);

    // Change apiUrl value based on User's RegionId
    const token = localStorage.getItem('WWW-Authenticate');
    const decodedData = this.decodeJwt(token);

    if (decodedData && Object.keys(decodedData).includes('RegionId')) {
      const { RegionId: region } = decodedData;
      const regionId = region;

      if (regionId === 1) {
        // Region is Indonesia
        this.apiUrl = `${process.env.VUE_APP_API_URL_ID}/api/v1`;
      } else if (regionId === 2) {
        // Region is UK
        this.apiUrl = `${process.env.VUE_APP_API_URL_UK}/api/v1`;
      }
    }
  },

  computed: {
    getHeaders() {
      const token = localStorage.getItem('WWW-Authenticate');
      return {
        Authorization: `Bearer ${token}`,
      };
    },
  },

  methods: {
    ...mapMutations({
      updateForm: 'UPDATE_FORM',
      setProcessing: 'websender/SET_PROCESSING',
    }),

    decodeJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

        const decodedData = JSON.parse(jsonPayload);
        return decodedData;
      } catch (err) {
        this.$showError(this, err);
        console.error('Failed to validate region'); // eslint-disable-line
        return 0;
      }
    },

    downloadSampleFile() {
      const data = [
        // OMG-3588: Malformed contacts due to inconsistent CSV column header
        { Mobile: 'Mobile', 'First name': 'FirstName', 'Last name': 'LastName' },
        { Mobile: 6596793616, 'First name': 'Chris', 'Last name': 'Shiflet' },
        { Mobile: '6596790613', 'First name': 'Don Don', 'Last name': 'Ampalayo' },
        { Mobile: 6596790641, 'First name': 'John', 'Last name': 'Boonham' },
        { Mobile: 6596793611, 'First name': 'Chris', 'Last name': 'Shiflet' },
        { Mobile: 6596780611, 'First name': 'Sean', 'Last name': 'Yseult' },
        { Mobile: 6596790612, 'First name': 'Mike', 'Last name': 'Pinisi' },
        { Mobile: 6596790614, 'First name': 'Don Don', 'Last name': 'Ampalayo' },
        { Mobile: 6596790642, 'First name': 'John', 'Last name': 'Boonham' },
        { Mobile: 6596793616, 'First name': 'Chris', 'Last name': 'Shiflet' },
        { Mobile: 6596780617, 'First name': 'Sean', 'Last name': 'Yseult' },
        { Mobile: 6596790612, 'First name': 'Mike', 'Last name': 'Pinisi' },
        { Mobile: 6596790614, 'First name': 'Don Don', 'Last name': 'Ampalayo' },
        { Mobile: 6596790642, 'First name': 'John', 'Last name': 'Boonham' },
        { Mobile: 6596793616, 'First name': 'Chris', 'Last name': 'Shiflet' },
        { Mobile: 96780617, 'First name': 'Sean', 'Last name': 'Yseult' },
      ];

      const options = {
        fieldSeparator: ',',
        decimalSeparator: '.',
        title: 'sample_file',
        filename: 'sample_file',
        headers: ['Mobile', 'First name', 'Last name'],
        useKeysAsHeaders: true
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
    },

    handleBeforeUpload() {
      this.uploading = true;
    },

    handleProgress(p) {
      if (Number(p.percent) === 100) {
        this.$emit('set-processing', true);
      }
    },

    handleSuccess(resp) {
      const {
        fileName,
        fileType,
        location,
      } = resp;

      this.uploading = false;
      this.updateForm({
        Recipients: {
          ...this.form.Recipients,
          source: 'UPLOAD',
          FileName: fileName,
          FileType: fileType,
          FileUrl: location,
          columnLabels: {
            MSISDN: -1,
            FirstName: -1,
            LastName: -1,
            ClientMessageId: -1,
          },
        },
      });
    },

    handleError(err) {
      if (window.Bugsnag) {
        window.Bugsnag.notify(err);
      }

      let emsg = 'Upload failed';

      if (err.message.match(/not supported/i)) {
        emsg = this.$t('errors.file_not_supported');
      } else if (err.message.match(/(less|than|30|mb)/i)) {
        emsg = this.$t('errors.file_size_limit', { size: '30MB' });
      }

      this.uploading = false;
      // this.$message.error(emsg);
      this.$notify.error({
        title: emsg,
      });
      this.$emit('set-processing', false);
    },
  },
};
</script>

<style lang="scss">
.upload-recipients {
  .el-upload { width: 100%; }
  .el-upload-dragger { width: 550px; height: 200px; }
}
</style>
