import http from '../../../utils/http';

export default {
  // Fetch groups data
  getGroups: async ({ commit }, query = null) => {
    try {
      let url = '/contacts-management/groups?offset=0&limit=500';
      if (query && query !== '') {
        url = url.concat(`&search=${query}`);
      }
      const { data } = await http.v1.get(url);
      commit({ type: 'SET_GROUP', group: data.items });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getContactByMSISDN: async ({ commit }, query) => {
    try {
      let url = '/contacts-management/contacts?offset=0&limit=20';
      if (query && query !== '') {
        url = url.concat(`&query=${query}`);
      }
      const { data } = await http.v1.get(url);
      commit({ type: 'SET_CONTACT', contact: data.items });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getRecipientsSummary: async ({ commit }, payload) => {
    try {
      const { data } = await http.v1.post(
        '/groups/get-totals-mcs',
        payload,
      );
      commit({ type: 'SET_SUMMARY', summary: data || {} });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  downloadInvalid: async ({ commit }, payload) => {
    try {
      const resp = await http.v1.post(
        '/websender/signed-invalid-url',
        payload,
      );

      const { data } = resp;

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
