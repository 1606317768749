<template>
  <div class="voice-speed mb-5">
    <div class="w-2/3">
      <div v-if="!label">
        <h5 class="text-black font-medium mb-2 text-sm label__asterisk">
          {{ $t('column_labels.speed') }}
        </h5>
        <p class="mb-4 text-grey-dark text-xs">
          {{ $t('mcs.messages.voice.speed[0]') }}
        </p>
      </div>
      <div class="flex w-2/3 slider ml-2">
        <div class="flex-auto">
          <el-slider
            v-model="slide"
            :step=".01"
            :max="2"
            :min="0.5"
            :show-input-controls="false"
            @change="handleSliderChange"
          >
          </el-slider>
          <div class="flex">
            <div
              v-for="i in this.valid"
              :key="i"
              :class="[
                'flex-1 text-grey-dark text-xs',
                { 'text-left' : Number(i) === 0.5},
                { 'text-left -ml-1' : Number(i) === 1},
                { 'text-right' : Number(i) === 2},
              ]"
            >
              {{ i }}
            </div>
          </div>
        </div>
        <div class="flex-shrinkm ml-5">
          <el-input-number
            controls-position="right"
            :max="2"
            :step=".01"
            :min="0.5"
            v-model="selected"
            size="mini"
            :style="{ width: '100px'}"
            @change="handleInputChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'message_voice_speed',

  props: {
    speed: {
      type: Number,
      default: 1,
    },

    label: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: 1,
      slide: 1,
      valid: [0.5, 1, 2],
    };
  },

  created() {
    this.selected = this.speed;

    if (Number(this.speed) < 0.5 || Number(this.speed) > 2) {
      this.slide = 1;
    } else {
      this.slide = this.speed;
    }
  },


  methods: {
    handleSliderChange(v) {
      let value = Number(v);

      if (Number(v) < 0.5 || Number(this.speed) > 2) {
        value = 1;
      }

      this.selected = Number(value);

      this.$emit('update-speed', this.selected, 'speed');
    },

    handleInputChange(v) {
      if (Number(this.speed) < 0.5 || Number(this.speed) > 2) {
        this.selected = 1;
        this.slide = 1;
      } else {
        this.slide = Number(v);
      }

      this.$emit('update-speed', Number(this.selected), 'speed');
    },
  },
};
</script>
