import Vue from 'vue';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import VueSanitize from 'vue-sanitize';
// import VueTelInput from 'vue-tel-input';
import singleSpaVue from 'single-spa-vue';
import VueI18n from 'vue-i18n';
// import 'vue-tel-input/dist/vue-tel-input.css';

import App from './App.vue';
import routes from './router';
import store from './store';

// Plugins
import VueJstz from './plugins/vue-jstz';
import Wc from './plugins/vue-wc';
import CurrencySymbol from './plugins/vue-currency';
import Cons from './plugins/vue-constants';

if (window.Bugsnag) {
  window.Bugsnag.getPlugin('vue')
    .installVueErrorHandler(Vue);
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('SELECTED_LOCALE') || 'en', // set locale
  fallbackLocale: 'en',
});

// Vue.use(VueTelInput);
Vue.use(VueRouter);
Vue.use(VueJstz);
Vue.use(Cons);
Vue.use(Wc);
Vue.use(CurrencySymbol);


const sOptions = {
  allowedTags: ['b', 'strong', 'p', 's', 'em', 'br', 'del', 'strike', 'i', 'mark', 'span', 'pre'],
  allowedAttributes: {
    span: ['class'],
  },
};

Vue.use(VueSanitize, sOptions);

const router = new VueRouter({ // eslint-disable-line
  base: '/multichannel-sender',
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // Prevent rerouting if app is unmounted
  if (!window.location.pathname.match(/^\/multichannel/i)) {
    return 0;
  }
  next();
  return 0;
});

// eslint-disable-next-line
__webpack_public_path__ = process.env.VUE_APP_BASE_URL

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#multichannel-sender',
    i18n,
    router,
    store,
    render(h) {
      // check root config for locale messages in main portal
      Object.keys(this.localeMessages).forEach((v) => {
        this.$i18n.setLocaleMessage(v, this.localeMessages[v]);
      });

      const {
        localeMessages, langUtil, appConfig, telemetry,
      } = this;

      Vue.prototype.$telemetry = telemetry;

      Vue.use(ElementUI, {
        i18n: (key, value) => this.$i18n.t(key, value),
      });

      // Assign appConfig so it is accessible to all components
      Vue.prototype.appConfig = appConfig;

      return h(App, {
        props: {
          localeMessages, langUtil, appConfig, telemetry,
        },
      });
    },
    setPublicPath(path) {
      // eslint-disable-next-line
      __webpack_public_path__ = path
    },
  },
});

export const bootstrap = [
  vueLifecycles.bootstrap,
];

export const mount = [
  vueLifecycles.mount,
];

export const unmount = [
  vueLifecycles.unmount,
];
