<template>
<el-dialog
  :title="$t('mcs.messages.chat_apps.templates[1]')"
  :visible="modalVisible"
  width="90%"
  top="60px"
  destroy-on-close
  @close="closeModal"
>
  <div class="flex flex-col wa-templates h-full"
     v-loading="loading">
    <div class="flex-auto">
      <div class="mb-5 flex">
        <div class="flex-initial mr-5">
          <h5 class="mb-2">{{ $t('column_labels.template_categories') }}</h5>
          <el-select
            v-model="category"
            filterable
            size="small"
            style="width: 200px;">
            <el-option
              v-for="(k, i) in categories"
              :key="i"
              :label="categories[i] === 'all' ? $t(`chat_apps.categories.All`) : $t(`chat_apps.categories.${categories[i]}`)"
              :value="i">
            </el-option>
          </el-select>
        </div>
        <div
          v-if="!hideHideTypes"
          class="flex-initial mr-5"
        >
          <h5 class="mb-2">{{ $t('column_labels.headers') }}</h5>
          <el-select
            v-model="header"
            filterable
            size="small"
            style="width: 150px;">
            <el-option
              v-for="(k, i) in headers"
              :key="i"
              :label="headers[i]"
              :value="i">
            </el-option>
          </el-select>
        </div>
        <div
          v-if="!hideHideTypes"
          class="flex-initial mr-5"
        >
          <h5 class="mb-2">{{ $t('column_labels.buttons') }}</h5>
          <el-select
            v-model="button"
            filterable
            size="small"
            style="width: 150px;">
            <el-option
              v-for="(k, i) in buttons"
              :key="i"
              :label="buttons[i]"
              :value="i">
            </el-option>
          </el-select>
        </div>
        <div class="flex-initial mr-24">
          <h5 class="mb-2">{{ $tc('column_labels.language', 2) }}</h5>
          <el-select
            v-model="language"
            filterable
            style="width: 200px;"
            size="small">
            <el-option
              v-for="(k, i) in languages"
              :key="i"
              :label="languages[i] === 'all' ? $t('column_labels.all') : languages[i]"
              :value="i">
            </el-option>
          </el-select>
        </div>
        <div class="flex-initial ml-10">
          <h5 class="mb-2"> &nbsp; </h5>
          <el-input
            size="small"
            style="width: 360px;"
            :placeholder="$t('mcs.messages.chat_apps.templates[0]')"
            suffix-icon="el-icon-search"
            v-model="search" />
        </div>
      </div>
    </div>

    <div class="flex-auto min-h-custom">
      <el-table
        :data="tableData.paginated"
        class="w-full">
        <el-table-column
          :width="250"
          :label="$t('column_labels.template_name')">
          <template slot-scope="scope">
            <span class="text-xs truncate">
              <text-highlight
                :queries="search"
                :highlightStyle="{ background: 'none'}"
              >
                {{ scope.row.templateName }}
              </text-highlight>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :width="150"
          :label="$t('column_labels.category')">
          <template slot-scope="scope">
            <span class="text-xs">
              {{ scope.row.categoryName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :width="80"
          :label="$t('column_labels.headers')">
          <template slot-scope="scope">
            <div class="text-xs ml-2 capitalize">
              {{
                media.includes(getComponents(scope.row.components).header.type)
                ? $t('mcs.messages.headers.media') : getComponents(scope.row.components).header.type || $t('app_labels.none') }}
            </div>
          </template>
        </el-table-column><el-table-column
          :width="120"
          :label="$t('column_labels.buttons')">
          <template slot-scope="scope">
            <div class="text-xs ml-2 btn-vals">
              {{ getButtonsColumnValue(getComponents(scope.row.components)) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('column_labels.message')">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              trigger="hover"
              width="400">
              <div class="bg-white p-1">
                <p class="text-black text-xs font-medium mb-1">
                  {{ scope.row.templateName }}
                </p>
                <p class="text-grey text-xs mb-3">
                  {{ scope.row.languageName }}
                </p>
                <div
                  class="p-1 bg-grey-lighter text-xs rounded-lg components-mm"
                  :style="{ padding: '2px'}"
                >
                  <div
                    v-if="getComponents(scope.row.components).header.type &&
                    getComponents(scope.row.components).header.type !== 'text'"
                    class="mb-2 bg-white rounded-t-lg">
                    <div>
                      <img
                        :src="urlResolver(
                          bgUrl,
                          require(`@/assets/media/${getMediaIcons(getComponents(scope.row.components).header.type)}.svg`)
                        )"
                        class="align-middle text-center px-2 py-4"
                        :alt="getComponents(scope.row.components).header.type"
                      />
                    </div>
                    <div
                        v-if="getComponents(scope.row.components).header.type === 'location' 
                        && getComponents(scope.row.components).header.value.name
                        && getComponents(scope.row.components).header.value.address"
                        class="bg-grey-shade-custom p-2"
                      >
                        <div class="ml-2 text-left break-words">
                          {{ getComponents(scope.row.components).header.value.name }}
                        </div>
                        <div class="ml-2 text-grey-darker text-left break-words">
                          {{ getComponents(scope.row.components).header.value.address }}
                        </div>
                    </div>
                  </div>
                  <div
                    v-if="getComponents(scope.row.components).header.type === 'text'"
                    class="mb-2 font-medium text-left px-2 truncate py-1">
                    {{ getComponents(scope.row.components).header.value }}
                  </div>
                  <div class="px-2 whitespace-pre-wrap break-words pt-1"
                    v-html="formatText(getComponents(scope.row.components).body)" />
                  <div
                    v-if="getComponents(scope.row.components).footer"
                    class="px-2 text-grey-darker text-left mt-2 py-1"
                    :style="{ fontSize: '10px'}">
                    {{ getComponents(scope.row.components).footer }}
                  </div>
                  <div
                    class="mt-3 border-t solid border-grey-light text-grey-dark p-2 text-sm text-center"
                    v-if="getComponents(scope.row.components).buttons
                      && getComponents(scope.row.components).buttons.length
                      && getComponents(scope.row.components).buttons.some((v) => ['url', 'phone_number'].includes(v.type.toLowerCase()))"
                  >
                    <div
                      v-for="(btn,i) in getComponents(scope.row.components).buttons"
                      :key="i"
                      class="py-2"
                    >
                      <div v-if="btn.type === 'PHONE_NUMBER'">
                        <span class="text-blue hover:text-blue-light no-underline cursor-pointer">
                          <img
                            :src="getTemplateIcon(phoneIcon)"
                            class="align-middle"
                            :style="{height: '15px'}"
                          />
                          <span class="align-middle"> {{ btn.text }} </span>
                        </span>
                      </div>

                      <div v-if="btn.type === 'URL'">
                        <span class="text-blue hover:text-blue-light no-underline cursor-pointer">
                          <img
                            :src="getTemplateIcon(externalLinkIcon)"
                            class="align-middle"
                            :style="{height: '15px'}"
                          />
                          <span class="align-middle"> {{ btn.text }} </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="getComponents(scope.row.components).buttons
                      && getComponents(scope.row.components).buttons.length
                      && getComponents(scope.row.components).buttons.some((v) => v.type.toLowerCase() === 'quick_reply' || v.type.toLowerCase() === 'otp')"
                    class="mt-3"
                  >
                    <div class="flex flex-wrap">
                      <div
                        v-for="(btn,i) in getComponents(scope.row.components).buttons"
                        :key="i"
                        class="quick-btn px-1">
                        <el-button
                          size="small"
                          class="w-full block bg-white shadow rounded mb-3 text-blue border border-transparent"
                        >
                          {{ btn.text }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span
                class="text-xs truncate text-left"
                slot="reference"
              >
                <text-highlight
                  :queries="search"
                  :highlightStyle="{ background: 'none'}"
                >
                  {{ getComponents(scope.row.components).body }}
                </text-highlight>
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :width="120"
          :label="$tc('column_labels.language', 1)">
          <template slot-scope="scope">
            <span class="text-xs">
              {{ scope.row.languageName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :width="150">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="selectTemplate(scope.row)">
            {{ $t('mcs.messages.index[15]') }}
          </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="flex-shrink mt-10">
      <Pagination
        v-if="total > 0"
        @next="nextPage"
        @prev="prevPage"
        @change-page-no="changePageNo"
        @change-page-size="changePageSize"
        :page-count="pageCount"
        :page-no="pageNo"
        :page-size="pageSize"
        :current-page-size="currentPageSize"
        :page-sizes="[5, 10, 15, 20]"
        :total="total"
      />
    </div>
  </div>
</el-dialog>
</template>
<script>

import _ from 'lodash';
import TextHighlight from 'vue-text-highlight';
import { urlResolver } from '@/utils/common';
import Pagination from '@/components/Pagination.vue';

import phoneIcon from '@/assets/misc/phone.svg';
import externalLinkIcon from '@/assets/misc/external-link.svg';

export default {
  name: 'messages_ca_templates',

  components: {
    Pagination,
    TextHighlight,
  },

  props: {
    templates: {
      type: Array,
      default: () => [],
    },

    categories: {
      type: Object,
      default: () => ({}),
    },

    languages: {
      type: Object,
      default: () => ({}),
    },

    modalVisible: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    hideTypes: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    const self = this;

    return {
      search: '',
      category: 'all',
      language: 'all',
      header: 'all',
      button: 'all',

      pageSize: 5,
      pageNo: 0,
      bgUrl: process.env.VUE_APP_BASE_URL.replace(/\/$/g, ''),
      headers: {
        all: self.$t('mcs.messages.headers.all'),
        text: self.$t('mcs.messages.headers.text'),
        media: self.$t('mcs.messages.headers.media'),
      },

      buttons: {
        all: self.$t('mcs.messages.buttons.all'),
        callToAction: self.$t('mcs.messages.buttons.call_to_action'),
        quickReply: self.$t('mcs.messages.buttons.quick_reply'),
        otp: self.$t('chat_apps.categories.OTP'),
      },
      media: ['image', 'video', 'document', 'location'],

      phoneIcon,
      externalLinkIcon,
    };
  },

  computed: {
    pageCount() {
      if (this.tableData
        && this.tableData.original
        && Array.isArray(this.tableData.original)) {
        return Math.ceil(this.tableData.original.length / this.pageSize);
      }

      return 0;
    },

    currentPageSize() {
      if (this.tableData
        && this.tableData.paginated
        && Array.isArray(this.tableData.paginated)) {
        return this.tableData.paginated.length;
      }

      return 0;
    },

    total() {
      if (this.tableData
        && this.tableData.original
        && Array.isArray(this.tableData.original)) {
        return this.tableData.original.length;
      }

      return 0;
    },

    tableData() {
      let templateData = this.templates.slice(0);
      const start = this.pageNo * this.pageSize;
      const end = start + this.pageSize;

      if (this.category !== 'all') {
        templateData = templateData.filter(v => v.category === this.category);
      }

      if (this.language !== 'all') {
        templateData = templateData.filter(v => v.language === this.language);
      }

      if (this.header !== 'all') {
        if (this.header === 'text') {
          templateData = templateData
            .filter(v => v.components.find(t => t.type.toLowerCase() === 'header' && t.format.toLowerCase() === 'text'));
        }

        if (this.header === 'media') {
          templateData = templateData
            .filter(v => v.components.find(t => t.type.toLowerCase() === 'header' && this.media.includes(t.format.toLowerCase())));
        }
      }

      if (this.button !== 'all') {
        if (this.button === 'callToAction') {
          templateData = templateData
            .filter(v => v.components.find(t => t.type.toLowerCase() === 'buttons' && t.buttons.some(b => ['url', 'phone_number'].includes(b.type.toLowerCase()))));
        }

        if (this.button === 'quickReply') {
          templateData = templateData
            .filter(v => v.components.find(t => t.type.toLowerCase() === 'buttons' && t.buttons.some(b => b.type.toLowerCase() === 'quick_reply')));
        }

        if (this.button === 'otp') {
          templateData = templateData
            .filter(v => v.components.find(t => t.type.toLowerCase() === 'buttons' && t.buttons.some(b => b.type.toLowerCase() === 'otp')));
        }
      }

      if (this.search.trim().length) {
        const searchString = this.$sanitize(this.search);
        const filters = ['templateName', 'text'];
        const result = templateData
          .filter(row => filters.some((k) => {
            let s = row[k];

            if (s === 'text') {
              const body = row.components.find(v => v.type.toLowerCase() === 'body');

              s = body.text;
            }
            if (s) {
              return s
                .toLowerCase()
                .includes(searchString.toLowerCase());
            }
            return 0;
          }));

        return {
          paginated: result.slice(start, end) || [],
          original: result || [],
        };
      }

      return {
        paginated: templateData.slice(start, end) || [],
        original: templateData || [],
      };
    },
  },

  watch: {
    'tableData.original': {
      handler(n, o) {
        if (n && Array.isArray(n)
          && o && Array.isArray(o)
          && n.length !== o.length) {
          this.pageNo = 0;
        }
      },
    },
  },

  methods: {
    getTemplateIcon(icon) {
      return `${process.env.VUE_APP_BASE_URL.replace(/\/$/g, '')}${icon}`;
    },

    formatText(text) {
      return _.unescape(this.$sanitize(text));
    },

    nextPage() {
      this.pageNo = this.pageNo + 1;
    },

    prevPage() {
      this.pageNo = this.pageNo - 1;
    },

    getMediaIcons(type = 'image') {
      const iconColor = 'dark';

      return `${type}-${iconColor}`;
    },

    getComponents(components = []) {
      return components.reduce((a, b) => {
        const type = b.type.toLowerCase();

        if (type === 'header') {
          if (b.format.toLowerCase() !== 'text') {
            if (b.format.toLowerCase() === 'location' && b.example) {
              a[type] = {
                type: b.format.toLowerCase(),
                value: {
                  latlong: b.example.headerHandle[0],
                  name: b.example.headerHandle[1],
                  address: b.example.headerHandle[2],
                }
              };
            } else {
              // eslint-disable-next-line no-param-reassign
              a[type] = {
                type: b.format.toLowerCase(),
                value: '',
              };
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            a[type] = {
              type: b.format.toLowerCase(),
              value: b.text,
            };
          }
        } else if (['body', 'footer'].includes(type)) {
          // eslint-disable-next-line no-param-reassign
          a[type] = b.text;
        } else if (type === 'buttons') {
          // eslint-disable-next-line no-param-reassign
          a[type] = b.buttons;
        }

        return a;
      }, {
        header: {},
        footer: '',
        body: '',
        buttons: [],
      });
    },

    changePageNo(pageNo) {
      this.pageNo = pageNo - 1;
    },

    changePageSize(size) {
      this.pageSize = size;
      this.pageNo = 0;
    },

    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },

    reset() {
      this.search = '';
      this.language = 'all';
      this.category = 'all';
      this.header = 'all';

      this.pageSize = 5;
      this.pageNo = 0;
    },

    selectTemplate(row) {
      this.reset();
      this.$emit('select-template', row);
    },

    urlResolver(url, path) {
      return urlResolver(url, path);
    },

    getButtonsColumnValue(components) {
      if (components.buttons && components.buttons.length) {
        const types = components.buttons.slice(0).map(v => v.type.toLowerCase());

        return _.uniq(types).toString().replace(',', ', ').replace(/_/g, ' ')
          .toUpperCase();
      }

      return 'none';
    },
  },
};
</script>
<style lang="scss">
  .wa-templates {
    .min-h-custom {
      min-height: calc(100vh - 400px);
    }

    .component-header .text-left {
      text-align: left !important;
    }

    .btn-vals {
      word-break: break-word !important;
    }

    .bg-grey-shade-custom {
      background-color: #f5f5f5;
    }
  }
</style>
