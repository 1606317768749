import http from '@/utils/http';


export default {
  // Fetch subaccounts list
  async getSMSSubAccounts({ commit }) {
    try {
      const { data } = await http.v2.get('/mcs/sub-accounts/sms');

      commit({ type: 'SET_SUBACCOUNTS', data });
      return data;
    } catch (err) {
      commit({ type: 'SET_SUBACCOUNTS', payload: [] });
      commit('API_ERROR', err, { root: true });
      // throw new Error(err.message);
      return Promise.reject(err);
    }
  },

  async getCASubAccounts({ commit }) {
    try {
      const { data } = await http.v2.get('/mcs/sub-accounts/chatapps');
      commit({ type: 'SET_SUBACCOUNTS', data });

      return data;
    } catch (err) {
      commit({ type: 'SET_SUBACCOUNTS', payload: [] });
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getVoiceSubAccounts({ commit }) {
    try {
      const { data } = await http.v2.get('/voice/sub-accounts/details');
      commit({ type: 'SET_SUBACCOUNTS', data });
      return data;
    } catch (err) {
      commit({ type: 'SET_SUBACCOUNTS', payload: [] });
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getSMSEngageSubAccounts({ commit }) {
    try {
      const { data } = await http.v2.get('/mcs/sms-engage/sub-accounts');
      commit({ type: 'SET_SUBACCOUNTS', data });
      return data;
    } catch (err) {
      commit({ type: 'SET_SUBACCOUNTS', payload: [] });
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getBalance({ commit }) {
    try {
      const bal = await http.v1.get('/balance');

      let balance = {};


      if (bal.data && Object.keys(bal.data).length) {
        balance = bal.data || {};
      }


      commit('SET_USER_BALANCE', { balance });

      return balance;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getUser({ commit }) {
    try {
      const cpv3User = localStorage.getItem('CPV3_User');
      const userData = JSON.parse(cpv3User) || {};

      const token = localStorage.getItem('WWW-Authenticate');
      const user = { ...userData, Token: token };

      commit('SET_USER', { user });

      return user;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return Promise.reject(err);
    }
  },
};
