<template>
  <div class="voice-lang mb-5">
    <div class="w-2/3">
      <div v-if="!label">
        <h5 class="text-black font-medium mb-2 text-sm label__asterisk">
          {{ $tc('column_labels.voice_profile', 1) }}
        </h5>
        <p class="mb-4 text-grey-dark text-xs">
          {{ $t('mcs.messages.voice.profiles[0]') }}
        </p>
      </div>
      <div class="border solid border-grey-light rounded relative w-3/4">
        <el-dropdown
          size="small"
          class="w-full outline-none px-3 py-2"
          trigger="click"
          placement="top-start"
          @command="handleCommand"
        >
          <div class="flex">
            <div class="flex-auto">
              <span class="mr-3 text-xs">{{ selected.code }} </span>
              <span class="text-xs font-medium">{{ selected.profile }} </span>
            </div>
            <div class="flex-shrink">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </div>
          <el-dropdown-menu
            slot="dropdown"
            class="dp-menu"
          >
            <el-dropdown-item
              v-for="i in selectedProfiles"
              :key="i.code"
              :command="`${i.code}|${i.profile}`"
              :class="[{ 'selected': selected.profile === i.profile }]"
            >
              <span class="mr-3 text-xs">{{ i.code }} </span>
              <span class="text-xs font-medium">{{ i.profile }} </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'message_voice_languages',

  props: {
    profile: {
      type: Object,
      default: () => ({
        code: 'en-US',
        profile: 'en-US-AriaRUS',
      }),
    },

    language: {
      type: Object,
      default: () => ({
        name: 'English (United States)',
        code: 'en-US',
      }),
    },

    profiles: {
      type: Array,
      default: () => [],
    },

    label: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: {},
    };
  },

  computed: {
    selectedProfiles() {
      return this.profiles.filter(v => v.code === this.language.code);
    },
  },

  created() {
    this.selected = this.profile;
  },

  watch: {
    profile(n) {
      if (n) {
        this.selected = n;
      }
    },
  },


  methods: {
    handleCommand(v) {
      const s = v.split('|');

      this.selected = {
        code: s[0],
        profile: s[1],
      };

      this.$emit('update-profile', { ...this.selected }, 'profile');
    },
  },
};
</script>
<style lang="scss">
  .dp-menu {
    overflow: auto;
    max-height: 300px;
    min-width: 400px;

    .selected {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
  }

</style>
