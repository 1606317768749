import moment from 'moment';
import momentDuration from 'moment-duration-format';

momentDuration(moment);

const fn = {
  /**
    * Return formated price
    * @param {Number} n -> number/value
    * @param {Number} d -> max decimal places
    * @param {String} l -> locale
    * @return {String} formatted price
  * */
  formatPrice(n, d = 7, l = 'en-US') {
    if (Number.isNaN(n)) return '0.00';

    const num = Number(n);
    const maximumFractionDigits = d;

    let ns = num.toLocaleString(l, { maximumFractionDigits });

    if (ns.split('.').length < 2) {
      ns = `${ns}.00`;
    }

    return ns;
  },

  timeFromCalendar(datetime) {
    return moment.utc(datetime).calendar(null, {
      sameDay: 'h:mm A',
      lastDay: '[Yest] h A',
      nextDay: '[Tom] h A',
      lastWeek: '[Last] ddd, h A',
      nextWeek: 'dd MMM',
      sameElse() {
        return this.year() === moment().year() ? 'D MMM' : 'D MMM YYYY';
      },
    });
  },

  // e.g. 12 Jun 2019 03:59 PM
  formatDateTime(datetime) {
    return moment.utc(datetime).format('D MMM YYYY hh:mm A');
  },

  // e.g. 12 Jun 2019
  formatDate(datetime) {
    return moment.utc(datetime).format('D MMM YYYY');
  },

  formatTz(tz) {
    const offset = new Date(tz.GMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
    let prefix = '-';
    if (tz.GMTOffset > 0) {
      prefix = '+';
    }
    return `(${tz.TimeZoneName} GMT${prefix}${offset})`; // eslint-disable-line
  },

  // e.g 1000 to '1,000'
  formatNumber(n) {
    return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },

  // e.g 'in 15 days', '1 hr ago' or '1 min ago'
  formatDateFromNow(datetime) {
    return moment.utc(datetime).fromNow();
  },

  dateIsInTheFuture(d) {
    const now = moment();
    return moment.utc(d).isAfter(now);
  },
};

const Wc = {
  install(Vue) {
    Vue.mixin({
      created() {
        // Global wc instance
        this.$wcsender = fn;
      },
    });

    Vue.prototype.$wcsender = fn; // eslint-disable-line
  },
};

export default Wc;
