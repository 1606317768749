<template>
  <div>
    <div
       v-if="userData.IsTrialAccount && form.Type.match(/SMS/i)"
      class="text-black text-xs p-3 bg-yellow-lightest
      border border-solid border-yellow rounded mb-5 w-3/5 leading-normal">
      <i18n
        v-if="userData.Phone && userData.PhoneVerified"
        path="mcs.channels[0]"
        tag="p"
        class="leading-normal"
      >
        <template #phoneno>
          <strong>{{ phoneNumber(userData.Phone) }}</strong>
        </template>
        <template #br>
          <br>
        </template>
        <template #topup>
          <a
          href="/messaging/payment"
          class="text-blue no-underline">{{ $t('mcs.top_up') }}</a>
        </template>
      </i18n>
      <i18n
        v-else
        path="mcs.channels[1]"
        tag="p"
        class="leading-normal"
      >
        <template #br>
          <br>
        </template>
        <template #topup>
          <a
          href="/messaging/payment"
          class="text-blue no-underline">{{ $t('mcs.top_up') }}</a>
        </template>
      </i18n>
    </div>
    <el-form
      :model="form"
      :rules="formRules"
      hide-required-asterisk
      ref="form"
      class="leading-none inline-block"
      @submit.prevent.native
    >
      <!-- APP TYPE -->
      <el-form-item prop="Type" :label="$t('mcs.channels[2]')">
        <span slot="label">
          <p class="mb-2 text-sm text-grey-darkest leading-none">
            {{ $t('mcs.channels[2]') }}
          </p>
        </span>
        <el-radio-group
          :value="form.Type"
          :disabled="loading"
          @input="v => updateAppType(v, true)"
        >

          <div class="inline-block">
            <div class="my-2">
              <el-radio
                id="sms-radio-btn"
                label="SMS"
                class="w-1/2 m-0"
                :disabled="!hasSMSAccess"
              >
                <span slot="label">
                  {{ $t('sidebar_menu_parent.SMS') }}
                </span>
              </el-radio>
            </div>
            <div v-if="hasEntitlement('cp-sms-engage')" class="my-2">
              <el-radio
                id="sms-engage-radio-btn"
                label="SMS_ENGAGE"
                :disabled="!hasSMSEngageAccess"
              >
                  {{ $t('mcs.sms_engage') }}
              </el-radio>
            </div>
          </div>
          <div class="inline-block ml-10">
            <div class="my-2">
              <el-radio
                id="chat-apps-radio-btn"
                :disabled="!hasChatAppsAccess"
                label="CHAT_APPS"
              >
                {{ $t('sidebar_menu_parent.Chat apps') }}
              </el-radio>
            </div>
            <div v-if="hasEntitlement('cp-voice')" class="my-2">
              <el-radio
                id="voice-radio-btn"
                :disabled="!hasVoiceAccess"
                label="VOICE"
              >
                  {{ $t('sidebar_menu_parent.Voice') }} ({{ $t('mcs.text_to_speech') }})
              </el-radio>
            </div>
          </div>
        </el-radio-group>
      </el-form-item>

      <hr class="mx-0 my-10 h-px bg-grey w-48" />

      <!-- SUBACCOUNT -->
      <el-form-item prop="SubAccountId" :label=" $t('fields.subaccount')">
        <span slot="label">
          <p class="mb-2 text-sm text-black leading-none text-left">{{ $t('fields.subaccount') }}</p>
          <p class="mb-2 text-xs text-grey-dark leading-none">
            {{ $t('mcs.channels[3]') }} </p>
        </span>
        <el-select
          id="sub-account"
          :disabled="loading"
          :value="form.SubAccountId"
          class="w-full"
          :placeholder="$t('validations.select', { value: $t('fields.subaccount') })"
          @change="v => updateSubAccount(v, true)"
        >
          <el-option
            v-for="(sa, i) in subaccountList"
            :key="i"
            :id="`sub-account-child-${i}`"
            :label="sa.SubAccountId"
            :value="sa.SubAccountUid"
          ></el-option>
        </el-select>
        <template v-if="form.SubAccountId && form.Type && form.Type.match(/CHAT_APPS/) &&
          form.FallbackConfig.length">
          <!-- <p class="mt-5 mb-2 text-xs text-grey-dark leading-tight">
            If a message is not delivered via the channel, we will re-send it via the
            <br/> next fallback channel as per your configuration
          </p> -->
          <i18n
            path="mcs.channels[4]"
            tag="p"
            class="mt-5 mb-2 text-xs text-grey-dark leading-tight"
          >
            <template #br>
              <br>
            </template>
          </i18n>

          <el-card
            v-if="form.FallbackConfig.length == 2
              && form.FallbackConfig[0].ChannelType === 'WA'
              && form.FallbackConfig[1].ChannelType === 'SM'"
            class="box-card"
            shadow="never"
          >
            <div class="mb-4">
              {{ $t('mcs.channels[7]') }}
              <el-link
                type="primary"
                href="https://connect.8x8.com/support/tickets/create"
                target="_blank"
              >
                {{ $t('mcs.channels[8]') }}
              </el-link>
              {{ $t('mcs.channels[9]') }}
            </div>
            <div>
              {{ $t('mcs.channels[10]') }}
              <b>{{ humanReadable(form.FallbackConfig[0].FallbackDelaySec) }}</b>
            </div>
          </el-card>

          <ChannelsFallback :channels="fallbackChannels()" class="mt-5 mb-2" />
        </template>
      </el-form-item>

      <!-- BUTTONS -->
      <el-form-item class="mt-10">
        <div v-if="!canSendCampaigns" class="text-red-light mb-2 text-sm">
          {{ $t('mcs.subscription_warning') }}
        </div>
        <el-button
          @click="$emit('discard')"
        >
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          :loading="loading"
          type="primary"
          @click="submit"
          id="add-recipients-btn"
        >
          {{ loading ? $t('wait.loading') : $t('mcs.channels[5]') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';

import { prettifyPhoneNumber, getHumanReadable } from '@/utils/common';

import ChannelsFallback from '@/components/ChannelsFallback.vue';

import handlePackageLimit from '@/utils/handlePackageLimit';

export default {
  name: 'channels',

  components: {
    ChannelsFallback,
  },

  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    breadcrumbs: {
      deep: true,
      handler() {
        const channel = this.breadcrumbs.find(c => c.name === 'CHANNEL');
        if (channel.error) {
          this.$refs.form.validate(() => {});
        } else {
          this.$refs.form.clearValidate();
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      form: 'getForm',
      subAccounts: 'user/getSubAccounts',
      userData: 'user/getUser',
      canSendCampaigns: 'user/getCanSendCampaigns',
      hasSMSAccess: 'user/getSmsAccess',
      hasChatAppsAccess: 'user/getChatAppsAccess',
      hasSMSEngageAccess: 'user/getSmsEngageAccess',
      hasVoiceAccess: 'user/getVoiceAccess',
    }),
  },

  data() {
    const self = this;

    return {
      loading: true,
      subaccountList: [],

      formRules: {
        Type: [
          { required: true, message: self.$t('validations.required', { value: self.$t('mcs.channels[6]') }), trigger: 'blur' },
        ],
        SubAccountId: [
          { required: true, message: self.$t('validations.required', { value: self.$t('fields.subaccount') }), trigger: 'blur' },
        ],
      },
    };
  },

  created() {
    if (!this.form.Type) {
      let type = '';


      if (this.hasVoiceAccess) {
        type = 'VOICE';
      }

      if (this.hasChatAppsAccess) {
        type = 'CHAT_APPS';
      }

      if (this.hasSMSEngageAccess) {
        type = 'SMS_ENGAGE';
      }

      // default type
      if (this.hasSMSAccess) {
        type = 'SMS';
      }
      this.updateAppType(type, true);
    } else {
      this.updateAppType(this.form.Type, false);
    }
  },

  methods: {
    ...mapActions({
      getSMSSubAccounts: 'user/getSMSSubAccounts',
      getCASubAccounts: 'user/getCASubAccounts',
      getVoiceSubAccounts: 'user/getVoiceSubAccounts',
      getSMSEngageSubAccounts: 'user/getSMSEngageSubAccounts',
    }),

    ...mapMutations({
      updateForm: 'UPDATE_FORM',
      setStep: 'SET_STEP',
    }),

    hasEntitlement(entitlement) {
      return handlePackageLimit(entitlement);
    },

    async fetchSubAccounts(v) {
      this.loading = true;

      try {
        switch (v) {
          case 'SMS': {
            await this.getSMSSubAccounts();
            break;
          }

          case 'CHAT_APPS': {
            await this.getCASubAccounts();
            break;
          }

          case 'VOICE': {
            await this.getVoiceSubAccounts();
            break;
          }

          case 'SMS_ENGAGE': {
            await this.getSMSEngageSubAccounts();
            break;
          }

          default:
        }

        this.subaccountList = this.subAccounts;
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error(err.message || 'Unable to get subaccounts');
      } finally {
        this.loading = false;
      }
    },

    updateFormValue(valObj) {
      this.updateForm(valObj);
    },

    updateSubAccount(v = '', isReset) {
      if (isReset) {
        // this is to avoid conflict with subaccounts and channels setup during composing message
        this.updateFormValue({
          Message: undefined,
          CampaignDetails: undefined,
          PriceRange: undefined,
        });
      }

      const subAccountDetail = this.subaccountList.find(
        d => d.SubAccountUid === v,
      );

      // If subaccount is CA and has fallback config; then set initial Channel
      if (this.form.Type && this.form.Type.match(/CHAT_APPS/i) && subAccountDetail) {
        const fallbackList = subAccountDetail.FallbackConfig || [];
        subAccountDetail.Channels = fallbackList.length ? fallbackList.map(c => c.ChannelType) : [];
      }

      const sb = subAccountDetail
        || {
          SubAccountUid: '',
          SubAccountId: '',
          Channels: '',
        };

      this.updateFormValue(sb);
    },

    async updateAppType(v, isReset) {
      if (isReset) {
        this.$emit('reset-all');
      }

      this.updateFormValue({ Type: v });

      await this.fetchSubAccounts(v);

      // Set subaccount to empty
      this.updateSubAccount(this.form.SubAccountUid || '', isReset);

      this.updateFallBackConfig();
    },

    updateFallBackConfig() {
      const subAccountDetail = this.subaccountList.find(
        d => d.SubAccountUid === this.form.SubAccountUid,
      );
      const fallbackconfig = subAccountDetail && subAccountDetail.FallbackConfig
        ? subAccountDetail.FallbackConfig
        : [];
      this.updateFormValue({ FallbackConfig: fallbackconfig });
    },

    fallbackChannels() {
      return this.form.FallbackConfig.map(c => (c.ChannelType === 'SM' ? 'SMS' : c.ChannelType));
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const { track } = this.$telemetry;

            track('channel_selected', { uiArea: 'multichannel_sender_campaign_page', channelSelected: this.form.Type });
            this.setStep('RECIPIENTS');
          } else {
            return false;
          }
          return false;
        });
      }
    },

    phoneNumber(pn) {
      return prettifyPhoneNumber(pn);
    },

    humanReadable(seconds) {
      return getHumanReadable(seconds);
    },
  },
};
</script>
<style lang="scss" scoped>
  .el-card {
    margin: 20px 0 20px;
    width: 500px;
    background: #fef9ee;
    line-height: normal;
  }
  .el-card div:first-child {
    text-align: justify;
  }
  .el-link {
    vertical-align: text-top;
  }
</style>
