<template>
  <div class="mb-3">
    <div class="border border-solid border-t-0 bg-grey-lightest rounded-b-lg -mt-2">
      <div class='flex-auto p-3 pt-5'>
       <p class="text-grey-dark text-sm mb-1">{{ $t('column_labels.process_on') }}</p>
        <div>
          <p class="text-black text-sm font-medium mb-1">{{ scheduleDate }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';

export default {
  name: 'send_process_when',

  props: {
    scheduleDate: {
      type: String,
      default: '',
    },
  },

};
</script>
