var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`ca-preview ${_vm.channel.toLowerCase()}`},[_c('h2',{staticClass:"text-base text-black font-normal mb-3"},[_vm._v("\n      "+_vm._s(_vm.$t('actions.preview'))+"\n    ")]),_c('p',{staticClass:"text-xs leading-tight text-grey-darker mb-2"},[_vm._v("\n      "+_vm._s(_vm.$t('mcs.messages.chat_apps.preview[0]'))+"\n    ")]),_c('div',{class:[
        'rounded-lg border solid border-grey',
        { 'border-purple' : _vm.channel.match(/VB/i)},
        { 'border-green' : _vm.channel.match(/LN|WA/i)}
      ]},[_c('div',{class:[
        'bg-white rounded-t-lg x p-3 overflow-x-auto solid border-b',
        { 'border-purple' : _vm.channel.match(/VB/i)},
        { 'border-green' : _vm.channel.match(/LN|WA/i)}
       ]},[_c('ChannelsFallback',{attrs:{"channels":_vm.channels,"with-label":false}})],1),(_vm.channel.match(/WA/i))?_c('div',{class:[
          'p-3 prev-content rounded-b-lg max-h-full overflow-y-auto',
        ]},[(Object.keys(_vm.header).length)?_c('div',{staticClass:"text-grey-dark bg-grey-lightest rounded-t shadow"},[(_vm.header.type !== 'text')?_c('div',{staticClass:"text-center"},[(_vm.header.type === 'image')?_c('div',{staticClass:"w-full"},[_c('ImageDisplay',{attrs:{"file-url":_vm.getFileUrl(_vm.customFields),"is-preview":Boolean(true)}})],1):_c('div',{staticClass:"py-6 px-2"},[_c('img',{staticClass:"align-middle",style:({width: '40px', height: '40px'}),attrs:{"src":_vm.urlResolver(
                    _vm.bgUrl,
                    require(`@/assets/media/${_vm.getMediaIcons(_vm.header.type, _vm.customFields)}.svg`)
                  ),"alt":_vm.header.type}})]),(_vm.header.type === 'location')?_c('div',{staticClass:"bg-grey-shade-custom text-left py-2"},[_c('span',{staticClass:"p-1 ml-2 text-xs block"},[_vm._v(_vm._s(_vm.getLocationFields(_vm.customFields).name))]),_c('span',{staticClass:"text-grey-dark p-1 ml-2 text-xs block"},[_vm._v(_vm._s(_vm.getLocationFields(_vm.customFields).address))])]):_vm._e()]):_vm._e()]):_c('div',{staticClass:"py-4 text-sm font-semibold text-black",domProps:{"innerHTML":_vm._s(_vm.replaceHeaderField(_vm.header.value, _vm.customFields, _vm.variables))}}),(_vm.getMessage() || _vm.footer)?_c('div',{class:[
              'min-w-full bg-white shadow p-2',
              'text-xs',
              { 'rounded-br-lg rounded-tr-lg rounded-bl-lg': !Object.keys(_vm.header).length },
            ]},[_c('div',{staticClass:"prev-wrapper whitespace-pre-wrap break-words text-black leading-tight",domProps:{"innerHTML":_vm._s(_vm.getWAPreviewContent(_vm.getMessage(), _vm.customFields, _vm.variables))}}),(_vm.footer)?_c('div',{staticClass:"mt-3 text-grey-dark",style:({fontSize: '10px'})},[_vm._v("\n            "+_vm._s(_vm.footer)+"\n          ")]):_vm._e(),(_vm.hasCTAButtons())?_c('div',{staticClass:"mt-3 border-t solid border-grey-light text-grey-dark p-2 text-base text-center"},_vm._l((_vm.waButtons),function(btn,i){return _c('div',{key:i,staticClass:"py-2"},[(btn.type.toLowerCase() === 'phone_number')?_c('div',[_c('a',{staticClass:"text-blue hover:text-blue-light no-underline",attrs:{"href":`tel:${btn.phoneNumber}`,"target":"_blank","rel":"nofollow noopener"}},[_c('img',{staticClass:"align-middle",style:({height: '15px'}),attrs:{"src":_vm.getTemplateIcon(_vm.phoneIcon)}}),_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(btn.text)+" ")])])]):_vm._e(),(btn.type.toLowerCase() === 'url')?_c('div',[_c('a',{staticClass:"text-blue hover:text-blue-light no-underline",attrs:{"href":`${_vm.replaceURLSuffix(btn.url, _vm.customFields, _vm.variables)}`,"target":"_blank","rel":"nofollow noopener"}},[_c('img',{staticClass:"align-middle",style:({height: '15px'}),attrs:{"src":_vm.getTemplateIcon(_vm.externalLinkIcon)}}),_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(btn.text)+" ")])])]):_vm._e()])}),0):_vm._e()]):_vm._e(),(_vm.hasQRButtons())?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.waButtons),function(btn,i){return _c('div',{key:i,staticClass:"quick-btn px-1"},[_c('el-button',{staticClass:"w-full block bg-white shadow rounded mb-3 text-blue border border-transparent",attrs:{"size":"small"}},[_vm._v("\n                "+_vm._s(btn.text)+"\n              ")])],1)}),0)]):_vm._e()]):_c('div',{class:[
            'bg-white p-3 prev-content max-h-full overflow-y-auto rounded-b-lg',
            { 'bg-purple-lightest-1' : _vm.channel.match(/VB/i)},
            { 'bg-green-lightest-1' : _vm.channel.match(/LN/i)}
          ]},[(_vm.body || _vm.imageInputs || Object.keys(_vm.buttonInputs).length > 0 && _vm.buttonInputs.title)?_c('div',{staticClass:"rounded-lg bg-white shadow"},[(_vm.getMessage())?_c('div',{class:[
                'body rounded-t-lg',
                { 'bg-grey-lighter' : _vm.channel.match(/VB|LN/i)},
              ]},[_c('div',{staticClass:"leading-normal min-w-full p-2 text-xs prev-wrapper whitespace-pre-wrap break-words max-h-full",domProps:{"innerHTML":_vm._s(_vm.getNonWAPreviewContent(_vm.getMessage(), _vm.customFields, _vm.variables))}})]):_vm._e(),(_vm.imageInputs)?_c('div',{staticClass:"image text-center"},[(_vm.imageInputs.previewUrl.match(/\/s3\/uploads/))?_c('div',{ref:"imageInput"}):_c('img',{attrs:{"src":_vm.imageInputs.previewUrl,"alt":_vm.imageInputs.previewUrl}})]):_vm._e(),(Object.keys(_vm.buttonInputs).length > 0 && _vm.buttonInputs.title)?_c('div',{class:[
                'button text-center px-4 py-4 rounded-b-lg',
                { 'bg-grey-lighter' : _vm.channel.match(/VB/i)},
              ]},[_c('a',{staticClass:"'bg-white rounded-full py-2 px-4 text-sm font-medium no-underline shadow border solid border-grey-lighter block'",class:[{
                  'bg-purple border-transparent text-white hover:bg-purple-light' :
                  _vm.channel.match(/VB/i)
                }],attrs:{"href":_vm.buttonInputs.url,"rel":"noopener noreferrer","target":"_blank"}},[_vm._v("\n              "+_vm._s(_vm.buttonInputs.title)+"\n            ")])]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }