<template>
  <div class="flex items-center">
    <div class="w-1/2">
      <div class="p-2">
        <DonutChart
          :title="`${$t('column_labels.total')} ${$t('column_labels.sent')}`"
          :data="chartData"
          :options="chartOptions"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div
        v-for="(data, i) in chartData"
        :key="i"
        :class="[
         { 'rounded-t-lg border-b-0 bg-grey-lighter' : i === 0 },
         { 'bg-grey-lightest' : i > 0 && i < chartData.length-1 },
         { 'rounded-b-lg border-t-0 bg-white' : i === chartData.length-1 }
        ]"
        class="border border-solid border-grey-light p-3 flex items-center"
      >
        <svg width="20" height="20" class="mr-3">
          <rect width="20" height="20" rx="3" :style="{fill: data.color}" />
        </svg>
        <div>
          <p class="text-grey-dark text-xs mb-1">{{ data.label }}</p>
          <p class="text-black text-base font-medium">{{ data.value.toLocaleString() }}</p>
        </div>
        <span class="text-black text-sm font-medium ml-auto">{{ getPercentage(data.value) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import DonutChart from '@/components/DonutChart.vue';

export default {
  name: 'ca-delivery-report',

  components: {
    DonutChart,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      chartOptions: {
        width: '100%',
        strokeWidth: 11,
        strokeLineCap: 'butt',
        labelColor: '#22292F',
        labelTop: false,
      },
      chartData: [
        {
          label: 'Delivered',
          value: this.data.delivered,
          color: '#4180ff',
        },
        {
          label: 'Undelivered',
          value: this.data.undelivered,
          color: '#2e384d',
        },
        {
          label: 'Rejected',
          value: this.data.rejected,
          color: '#e3ecff',
        },
      ],
    };
  },

  methods: {
    getPercentage(v) {
      return this.toPercentString((v / (this.data.total || 1)) * 100);
    },

    toPercentString(n) {
      return `${n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  display: inline-block;
}
</style>
