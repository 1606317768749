<template>
  <div class="mb-10">
    <div class="flex py-5">
      <div class="flex-1">
        <h1 class="text-xl text-color font-semibold page-title pt-2">
          {{ $t('mcs.campaigns.title') }}
          <el-popover
          popper-class="campaigns-info"
            placement="right"
            :title="$t('mcs.campaigns.title')"
            width="320"
            trigger="hover"
          >
            <i class="icon-novo-info-circle text-base font-semibold cursor-pointer" slot="reference" />
            <div class="el-popover__content">
              {{ $t('mcs.campaigns.table[0]') }}
            </div>
          </el-popover>
        </h1>
      </div>
      <div class="flex-1 text-right">
        <el-button
          type="primary"
          @click.prevent="$router.push('/send')"
          :disabled="!hasMCSRole()"
        >
          {{ $t('mcs.campaigns.filters[0]') }}
        </el-button>
      </div>
    </div>
    <div class="border-grey-lighter border solid flex flex-col w-full relative rounded bg-grey-inputs">
      <div v-if="!Object.keys(selectedCampaign).length" class="flex-shrink border-b border-grey-lighter">
        <Filters :loading="loading" :filters="filters" :channel-list="channelsJson" :status-list="statusJson" :default-filters="defaultFilters"
          :default-daterange="defaultDaterange" :user="user" :campaigns="campaigns" @update-filter="updateFilter" @reload-filter="reloadFilter" @reset-filter="resetFilter"/>
      </div>
      <div>
        <div class="relative">
          <div v-if="Object.keys(selectedCampaign).length"
            class="px-3 py-4  bg-grey-lightest text-sm mb-10 rounded-t">
            <div class="l-container">
              <div class="flex items-center">
                <a @click.prevent="selectCampaignItem({})" class="el-icon-back text-black no-underline text-xl" href></a>
                <div class="ml-5">
                  <span class="flex items-center">
                    <img :src="getChannelIcon(getProductDetail(selectedCampaign).campaignCode)"
                      class="block mx-auto mr-2 ms-channel-icon" />
                    {{ selectedCampaign.CampaignName || $t('column_labels.unnamed') }}
                  </span>
                </div>
                 <!-- <div class="ml-auto">
                  <el-button  type="primary" @click="downloadCampaign(selectedCampaign)">
                    {{ $t('actions.download') }}
                  </el-button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="relative">
          <div class="l-container">
            <!-- Show SMS campaign -->
            <SmsCampaignDetail v-if="Object.keys(selectedCampaign).length && selectedCampaign.Product.match(/SMS/g)"
              :loading="loading" :campaign="selectedCampaign" :show-message="showSmsMessageBody" :current-tz="tz.timeZone"
              :current-tz-offset="tz.timeZoneOffset" :get-local-time="getLocalTime" @reject-campaign="reject"
              @approve-campaign="approve" @cancel-campaign="cancel" @deselect-campaign="selectCampaignItem({})"
              @fetch-data="updateFilter" class="px-5 mb-5">
            </SmsCampaignDetail>
            <!-- Show CA campaign -->
            <CaCampaignDetail
              v-else-if="Object.keys(selectedCampaign).length"
              :loading="loading"
              :campaign="selectedCampaign"
              :fallback-detail="campaignFallbackDetail"
              :show-message="showChatMessageBody"
              :get-local-time="getLocalTime"
              @reject-campaign="reject"
              @approve-campaign="approve"
              @cancel-campaign="cancel"
              @deselect-campaign="selectCampaignItem({})"
              @fetch-data="updateFilter"
              class="px-5"
            />
            <!-- Show Table campaign -->
            <div v-else>
              <Table :loading="loading" :data="campaigns" :search="campaigns" @reject-campaign="reject"
                @approve-campaign="approve" @cancel-campaign="cancel" @select-campaign="selectCampaignItem"
                :has-mcs-role="hasMCSRole()"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!Object.keys(selectedCampaign).length" class="flex-shrink px-3 py-2 border-t border-grey-lighter">
        <Pagination :filters="filters" :loading="loading" :campaigns="campaigns" :pagination-limit="paginationLimit" @handle-page-change="handlePageChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import statusJson from '@/json/campaignStatus.json';
import channelsJson from '@/json/channels.json';
import CampaignListMixin from '@/mixins/campaign-list';
import TimeZone from '@/mixins/timezone';
import _ from 'lodash';
import CaCampaignDetail from './partials/CACampaignDetail.vue';
import Filters from './partials/FiltersNew.vue';
import SmsCampaignDetail from './partials/SMSCampaignDetail.vue';
import Table from './partials/Table.vue';
import Pagination from './partials/PaginationNew.vue';


export default {
  name: 'campaigns',

  components: {
    Filters,
    Pagination,
    Table,
    SmsCampaignDetail,
    CaCampaignDetail,
  },

  mixins: [
    CampaignListMixin,
    TimeZone,
  ],

  data() {
    return {
      channelsJson,
      statusJson,
      defaultDaterange: [
        Number(Moment().startOf('day').subtract(1, 'w')),
        Number(Moment().endOf('day')),
      ],
      paginationLimit: 10,
      defaultFilters: {},
    };
  },

  computed: {
    ...mapGetters({
      loading: 'campaigns/getLoading',
      campaigns: 'campaigns/getCampaigns',
      campaignFallbackDetail: 'campaigns/getCampaignFallbackDetail',
      selectedCampaign: 'campaigns/getSelectedCampaign',
      filters: 'campaigns/getFilters',
      socketId: 'socket/getSocketId',
      queueUrl: 'socket/getQueueUrl',
      showChatMessageBody: 'user/getChatMessageBodyAccess',
      showSmsMessageBody: 'user/getSmsMessageBodyAccess',
      tz: 'user/getTz',
      user: 'user/getUser',
    }),
  },

  created() {
    let channel = '';
    const fromSmsDashbaord = localStorage.getItem('sms-dashboard');
    if (fromSmsDashbaord === 'active') {
      channel = 'SM';
      localStorage.removeItem('sms-dashboard');
    }
    const params = {
      ...this.filters,
      channel,
      status: '',
      daterange: this.defaultDaterange,
      tz: this.tz.timeZone,
      offset: this.tz.timeZoneOffset,
      limit: this.paginationLimit,
      page: 1,
      campaignName: '',
    };

    this.defaultFilters = JSON.parse(JSON.stringify(params));

    this.getUser();
    this.getCASubAccounts();

    // Initialize default filter
    this.updateFilter(params);
  },

  methods: {
    ...mapMutations({
      setFilters: 'campaigns/SET_FILTERS',
      setSelectCampaignItem: 'campaigns/SET_SELECTED_CAMPAIGNS',
      setFallbackDetail: 'campaigns/SET_FALLBACK_DETAIL',
    }),

    ...mapActions({
      getCampaigns: 'campaigns/getCampaigns',
      getFallbackDetail: 'campaigns/getFallbackDetail',
      rejectCampaign: 'campaigns/rejectCampaign',
      approveCampaign: 'campaigns/approveCampaign',
      cancelCampaign: 'campaigns/cancelCampaign',
      sendCampaignToEmail: 'campaigns/downloadSMSCampaign',
      getUser: 'user/getUser',
      getCASubAccounts: 'user/getCASubAccounts',
    }),

    hasMCSRole() {
      const userRoles = _.get(this, 'user.Roles', []);

      return userRoles.includes('MultichannelSender_v2');
    },

    async downloadCampaign(campaign) {
      const {
        // eslint-disable-next-line camelcase
        CampaignId: campaign_id,
        // eslint-disable-next-line camelcase
        CampaignName: campaign_name,
        MsgTotal: total,
      } = campaign;

      const payload = {
        campaign_id,
        campaign_name,
        socket_id: this.socketId,
        queue_url: this.queueUrl,
        total,
      };

      try {
        await this.sendCampaignToEmail(payload);
        this.$notify({
          title: this.$t('mcs.campaigns.sms_details[0]'),
          message: this.$t('mcs.campaigns.sms_details[1]'),
          type: 'success',
        });
      } catch (err) {
        this.$showError(this, err);
        // this.$message.error(err.message || 'Failed to send campaign via email');
        // this.$notify.error({
        //   title: 'Error',
        //   message: 'Failed to download campaign',
        // });
      }
    },

    reject(campaign) {
      this.$prompt(
        this.$t('mcs.campaigns.index[0]'),
        this.$t('mcs.campaigns.index[1]', { campaign: campaign.CampaignName }),
        {
          confirmButtonText: this.$t('actions.reject'),
          cancelButtonText: this.$t('actions.cancel'),
          inputPlaceholder: this.$t('mcs.campaigns.index[2]'),
          inputPattern: /^.{10,}$/,
          inputErrorMessage: this.$t('validations.char_limit', { value: '10' }),
        },
      ).then(({ value }) => {
        this.rejectCampaign({
          Reason: value,
          ...campaign,
        }).catch((err) => {
          this.$showError(this, err);
        });
      }).catch(() => { });
    },

    approve(campaign) {
      this.approveCampaign({
        ...campaign,
        SocketId: this.socketId,
        QueueUrl: this.queueUrl,
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    cancel(campaign) {
      this.$confirm(
        this.$t('mcs.campaigns.index[3]'),
        this.$t('actions.cancel'), {
          confirmButtonText: this.$t('mcs.campaigns.index[4]'),
          cancelButtonText: this.$t('mcs.campaigns.index[5]'),
          type: 'warning',
        },
      ).then(() => {
        this.cancelCampaign(campaign).catch((err) => {
          this.$showError(this, err);
        });
      }).catch(() => { });
    },

    selectCampaignItem(c = {}) {
      const {
        CampaignId: campaignId,
        CampaignName: campaignName,
        SubAccountUid: subAccountUid,
        Product: product,
      } = c;
      this.setSelectCampaignItem(campaignId || '');


      const { track } = this.$telemetry;

      if (Object.keys(c) && campaignName) {
        track('campaign_details_viewed', {
          uiArea: 'multichannel_sender_campaign_page',
          campaignName,
        });
      }

      const emptyCampaign = !Object.keys(c).length;
      if (emptyCampaign) {
        // Set fallback detail to empty
        this.setFallbackDetail({ data: '' });
      } else if (!['SMS', 'VO'].includes(product)) {
        // Get fallback detail data
        this.getFallbackDetail({ campaignId, subAccountUid }).catch((err) => {
          this.$showError(this, err);
        });
      }
    },

    reloadFilter() {
      this.setFilters({
        ...this.filters,
        offset: this.tz.timeZoneOffset,
        timeZone: this.tz.timeZone,
      });

      this.getCampaigns().catch((err) => {
        this.$showError(this, err);
      });
      this.selectCampaignItem({});
    },

    resetFilter() {
      let channel = '';
      const fromSmsDashbaord = localStorage.getItem('sms-dashboard');
      if (fromSmsDashbaord === 'active') {
        channel = 'SM';
        localStorage.removeItem('sms-dashboard');
      }

      const params = {
        ...this.filters,
        channel,
        status: '',
        daterange: this.defaultDaterange,
        tz: this.tz.timeZone,
        offset: this.tz.timeZoneOffset,
        limit: this.paginationLimit,
        campaignName: '',
        page: 1,
      };

      // Initialize default filter
      this.updateFilter(params);
    },

    // Update filter state
    updateFilter(filter = {}) {
      this.setFilters({
        ...this.filters,
        ...filter,
        offset: this.tz.timeZoneOffset,
        timeZone: this.tz.timeZone,
        page: 1,
      });
      this.getCampaigns()
        .catch((err) => {
          this.$showError(this, err);
        });
      this.selectCampaignItem({});
    },

    // Update page
    handlePageChange(page) {
      this.setFilters({
        ...this.filters,
        page,
      });
      this.getCampaigns().catch((err) => {
        this.$showError(this, err);
      });
      this.selectCampaignItem({});
    },
  },
};
</script>

<style lang="scss" scoped>
.ms-channel-icon {
  width: 24px;
  height: 24px;
}

.pagination {
  background-color: #E4EDFF;

  &__arrow {
    background-color: #F2F5FF;
    transition: background-color 0.05s ease-in;

    &:hover {
      background-color: darken(#F2F5FF, 1);
    }

    &--disable {
      cursor: progress !important;
      opacity: 0.5;
    }
  }
}
</style>
<style lang="scss">
  .campaigns-info {
    word-break: break-word !important;
    padding: 0 !important;

    .el-popover__title {
      padding: 12px;
      border-bottom: 1px solid #f0f0f0;
    }

    .el-popover__content {
      padding: 0 12px 12px;
    }
  }
</style>
