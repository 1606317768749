<template>
  <div class="mb-5">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.prevent.native>
      <h5 class="text-black font-medium mb-2 label__asterisk">
        {{ $t('fields.sender_id') }}
      </h5>
      <p
        class="leading-tight text-grey-darker mb-2 text-xs w-3/5">
        {{ $t('mcs.messages.sms.msg_box[0]') }}
        <a href="/support/articles/360039522251?search_term=SMS-senderID"
          class="text-blue hover:text-blue-darker cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
          >
          {{ $t('mcs.messages.sms.msg_box[1]') }}
        </a>
      </p>
      <el-form-item prop="from">
        <el-select
          v-model="form.from"
          filterable
          :default-first-option="true"
          clearable
          allow-create
          size="small"
          class="w-1/2"
          :placeholder="$t('mcs.messages.sms.msg_box[2]')">
          <div v-loading="loading">
            <el-option
              v-for="item in senderIds"
              :key="item.SenderId"
              :label="item.SenderId"
              :value="item.SenderId">
              <span class="float-left">
                {{ item.SenderId }}
              </span>
              <span class="float-right">
                <el-button
                  type="text"
                  class="remove-sender-close cursor-pointer outline-0"
                  icon="el-icon-close"
                  @click.prevent.stop="$emit('remove-from-list', item.SenderId)" />
              </span>
              <span class="clearfix" />
            </el-option>
          </div>
        </el-select>
      </el-form-item>
      <div class="w-2/3">
        <div class="flex">
          <h5 class="text-black font-medium mb-2 flex-auto label__asterisk">
            {{ $t('column_labels.message') }}
          </h5>
          <div class="flex-shrink">
            <span class="text-grey-darker text-xs">
              {{ selected.charsUsed || 0 }} / {{ totalPartsChars || 160 }} {{$t('column_labels.characters')}}
            </span>
          </div>
        </div>
        <el-form-item prop="message">
          <el-input
            ref="msgBox"
            v-model="form.message"
            @change="onChange"
            :placeholder="$t('mcs.messages.sms.msg_box[3]')"
            type="textarea"
            class="text-xs"
            :rows="4"
            :disabled="!form.from" />
        </el-form-item>
      </div>
    </el-form>

    <div v-if="variables && variables.length">
      <p class="mb-2 text-xs"> {{ $t('mcs.messages.sms.msg_box[4]')}} </p>
      <div class="mb-2">
        <span
          v-for="i in variables"
          :key="i"
          class="mr-3 item-tag"
        >
          {{ i }}
          <button
            type="link"
            :disabled="!form.from"
            class="el-icon-plus el-icon--right"
            @click="addCustomField(i)"
          />
        </span>
      </div>
      <p class="text-xs">
        <span v-if="source === 'UPLOAD'">
          <a
            href="#"
            class="text-blue hover:text-blue-darker no-underline"
            @click.prevent="$emit('modify-custom-fields')">
            {{ $t('actions.modify') }}
          </a> {{ $t('mcs.messages.sms.msg_box[5]') }}.
        </span>
        <a
          href="https://support.wavecell.com/hc/en-us/articles/115005656203-How-can-I-add-custom-fields-on-my-messages-"
          class="text-blue hover:text-blue-darker no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >{{ $t('mcs.messages.sms.msg_box[6]') }}</a>
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import insertAtCursor from '@/utils/insertAtCursor';

export default {
  name: 'message_sms_sender_id',

  props: {
    senderIds: {
      type: Array,
      default: () => [],
    },

    selected: {
      type: Object,
      default: () => [],
    },

    variables: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    isSmsEngage: {
      type: Boolean,
      default: false,
    },

    source: {
      type: String,
      default: '',
    },
  },

  data() {
    const checkIfBlacklisted = async (rule, value, cb) => {
      // check if blacklisted
      const res = await this.validateSenderId({ senderId: value });

      if (!res.blacklisted) {
        cb();
      } else {
        cb(new Error(this.$t('mcs.messages.sms.msg_box[7]')));
      }
    };

    const self = this;

    return {
      form: {
        from: '',
        message: '',
      },

      urlRegex: new RegExp(self.$vars.SMS_ENGAGE_URL_REGEX, 'g'),

      rules: {
        from: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('fields.sender_id') }),
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
          {
            // pattern: /^((?=.*[a-zA-Z]{1,})(?=.*[0-9]?)(?=^[a-zA-Z0-9\_\-\.\!\"\#\$\%\&\'\(\)\*\+\,\/\:\;\<\=\>\?\@\[\\\]\^\{\|\}\~]+\s?[a-zA-Z0-9\_\-\.\!\"\#\$\%\&\'\(\)\*\+\,\/\:\;\<\=\>\?\@\[\\\]\^\{\|\}\~]+$).{1,11}|[0-9]{1,15})$/, // eslint-disable-line
            // eslint-disable-next-line no-useless-escape
            pattern: new RegExp(self.$vars.SENDER_ID_REGEX, 'g'),
            message: `${self.$t('validations.valid', { value: self.$t('fields.sender_id') })}. ${this.$t('mcs.messages.sms.msg_box[8]')}`,
            trigger: 'change',
            transform(value) {
              return value.trim();
            },
          },
          {
            validator: checkIfBlacklisted,
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
        ],

        message: [
          {
            required: true,
            message: self.$t('validations.required', { value: self.$t('column_labels.message') }),
            trigger: 'blur',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },
    };
  },

  computed: {
    totalPartsChars() {
      const { smsChars, smsParts } = this.selected;

      const chars = smsChars || 0;
      const parts = smsParts || 0;

      return chars * parts;
    },
  },

  watch: {
    'form.from': {
      handler(n) {
        if (this.$refs.form) {
          const self = this;
          this.$refs.form.validateField('from', (err) => {
            if (!err) {
              self.$emit('update-sender-id', n, 'senderId');
            } else {
              self.$emit('update-sender-id', '', 'senderId');
            }
          });
        }
      },
    },

    'form.message': {
      handler(n) {
        if (this.$refs.form) {
          const self = this;
          this.$refs.form.validateField('message', (err) => {
            if (!err) {
              self.$emit('update-message', n);
            } else {
              self.$emit('update-message', '');
            }
          });
        }
      },
    },

    // sometimes message is manipulated outside the message box
    'selected.message': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.message = newValue;
        }
      },
    },
  },

  created() {
    this.form.from = this.selected.senderId;
    this.form.message = this.selected.message;
  },

  methods: {
    ...mapActions({
      validateSenderId: 'websender/validateSenderId',
    }),

    addCustomField(field) {
      if (this.$refs.msgBox) {
        const cfText = `{{${field}}}`;
        const textArea = this.$refs.msgBox.$refs.textarea;

        const textfield = insertAtCursor(textArea, cfText);

        this.form.message = textfield.value;
      }
    },

    onChange(v) {
      this.$emit('on-message-changed', v);
    },
  },
};
</script>
